import Page from 'pages/Page'
import LoginForm from './LoginForm'

export default function Login() {
  return (
    <Page>
      <div className="flex items-center justify-center md:h-screen">
        <div className="relative mx-auto flex w-full max-w-[400px] flex-col space-y-2.5 p-4">
          <LoginForm />
        </div>
      </div>
    </Page>
  )
}
