import { useState } from 'react'
import {
  Flex,
  Text,
  NumberInput,
  Button,
  Card,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Icon,
  Subtitle
} from '@tremor/react'
import {
  PlusIcon,
  UserIcon,
  ArrowRightIcon,
  ArrowLeftIcon
} from '@heroicons/react/24/outline'
import { io } from 'socket.io-client'

import { usePagination } from '../../hooks/usePagination'
import { useNavigate } from 'react-router-dom'

const socket = io(`ws://localhost:8080`, {})

export default function List() {
  const { results, page, total, size, setPage, previous, next, pages, fetch } =
    usePagination('/employment')
  const navigate = useNavigate()
  const [count, setCount] = useState('')

  const seedEmployment = async () => socket.emit('seed', { count: count || 0 })
  socket.on('refresh', fetch)

  return (
    <>
      <Flex className="space-x-5 whitespace-nowrap">
        <Text className="whitespace-nowrap">Random Employees</Text>
        <NumberInput
          value={count}
          onChange={e => setCount(e.target.value)}
          placeholder="10000...."
        />
        <Button icon={PlusIcon} onClick={seedEmployment}>
          Create
        </Button>
      </Flex>
      <Card>
        <Flex>
          <Flex>
            <Text>
              {page * size} -{(page + 1) * size} / {total}
            </Text>
          </Flex>
          <Flex justifyContent="end" className="text-right space-x-2">
            <Button
              size="xs"
              className="h-8 w-8"
              variant="secondary"
              onClick={previous}
              icon={ArrowLeftIcon}
            />
            {
              // We want this to be the previous index as well
              pages().map(i => (
                <Button
                  key={i}
                  className="h-8 w-8"
                  size="xs"
                  variant={page == i ? 'primary' : 'secondary'}
                  onClick={() => {
                    setPage(i)
                  }}
                >
                  {`${i + 1}`}
                </Button>
              ))
            }
            <Button
              size="xs"
              className="h-8 w-8"
              variant="secondary"
              onClick={next}
              icon={ArrowRightIcon}
            />
          </Flex>
        </Flex>

        <Table className="mt-6">
          <TableBody>
            {results.map((e: any) => (
              <TableRow
                key={e.id}
                onClick={() => navigate(`/${e.id}/employment`)}
              >
                <TableCell style={{ width: '40px' }}>
                  <Icon icon={UserIcon} variant="solid" size="md" />
                </TableCell>
                <TableCell className="text-left">
                  <Text>
                    {e.first_name} {e.last_name}
                  </Text>
                  <Subtitle>{e.job_title}</Subtitle>
                </TableCell>
                <TableCell>
                  <Subtitle>{e.email}</Subtitle>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Card>
    </>
  )
}
