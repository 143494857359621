import { z } from 'zod'

export const LoginSchema = z.object({
  username: z.string(),
  isAuthenticated: z.boolean(),
  access_token: z.string()
})
export type Login = z.infer<typeof LoginSchema>

/**
 * This represents some generic auth provider API, like Firebase.
 */
const authProvider = {
  isAuthenticated: false,
  async signin(callback: () => Promise<Response>, username: string) {
    try {
      const response = await callback()
      const result = (await response.json()) as Awaited<
        Promise<{
          access_token: string
        }>
      >
      authProvider.isAuthenticated = true

      const login: Login = {
        isAuthenticated: true,
        username,
        access_token: result.access_token
      }
      localStorage.setItem('ADMIN-UI-LOGIN', JSON.stringify(login))
      return result
    } catch (error) {
      throw error as Error
    }
  },
  signout() {
    authProvider.isAuthenticated = false
    localStorage.removeItem('ADMIN-UI-LOGIN')
  }
}

export { authProvider }
