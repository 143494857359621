import { memo } from 'react'
import { Card, Title, Subtitle } from '@tremor/react'
import { BoltIcon } from '@heroicons/react/24/outline'

const icon = BoltIcon
const type = 'Constant'

export { icon, type }
export default memo(function Constant({ data: { node } }: any) {
  return (
    <Card>
      <Title>{`${node.name}`}</Title>
      <Subtitle>{`${node.value}`}</Subtitle>
    </Card>
  )
})
