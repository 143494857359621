import { Color } from '@tremor/react'
import { Edge } from 'reactflow'

import { colours } from './data'

export function addEdgeStyle(edge: Edge, color: Color, isSelected?: boolean) {
  const colour = colours[color][6]
  edge.style = {
    strokeWidth: isSelected ? 4 : 2,
    stroke: colour
  }
}

export function createStyledEdge(edge: Edge, color: Color): Edge {
  const colour = colours[color][6]
  return {
    ...edge,
    style: {
      strokeWidth: 2,
      stroke: colour
    },
    type: 'offset'
  }
}

export function createToggleHoverStyle(type: 'on' | 'off') {
  return (edge: Edge) => {
    const initialWidth = (edge.style?.strokeWidth as number) ?? 1
    edge.style = {
      ...edge.style,
      strokeWidth: type === 'on' ? initialWidth * 2 : initialWidth / 2
    }
  }
}

export function enableSelected(
  initialEdges: Edge[], // initialEdge does not have style prop
  selectedEdge: Edge | null
) {
  // Re-render edges from the initial edges caused by changes from contexts or rules
  return initialEdges
    ? initialEdges.map(e => {
        if (e.id === selectedEdge?.id) {
          // Keep the animated and style.strokeWidth props from the selected edge.
          // Leave the edge color and width under context to the later process when dealing with the context.
          return {
            ...e,
            ...selectedEdge,
            style: {
              strokeWidth: 2
            }
          }
        }
        return e
      })
    : []
}
