import { MutableRefObject, useEffect, useMemo } from 'react'
import { Edge } from 'reactflow'

import { createToggleHoverStyle } from '../../features/contexts/utils'
import { State } from 'stores/FunctionStore'

type UseSelectedEdgeProps = {
  edges: Edge[]
  setEdges: (arg: (edgeState: State['edges']) => State['edges']) => void
  selectedEdgeRef: MutableRefObject<Edge | null>
}

export function useSelectedEdge({
  edges,
  setEdges,
  selectedEdgeRef
}: UseSelectedEdgeProps) {
  const latestSelectedEdge = useMemo(() => {
    let latestSelectedEdge: Edge | null = null
    for (const edge of edges) {
      if (edge.selected) {
        latestSelectedEdge = edge
        break
      }
    }
    return latestSelectedEdge
  }, [edges])

  // Remove all the special styles on the last selected edge
  useEffect(() => {
    if (latestSelectedEdge?.id !== selectedEdgeRef.current?.id) {
      const lastSelectedEdgeId = selectedEdgeRef.current?.id
      selectedEdgeRef.current = latestSelectedEdge

      if (lastSelectedEdgeId) {
        setEdges(cur =>
          cur.map(c => {
            if (c.id === lastSelectedEdgeId) {
              const edge = { ...c }
              const toggleHoverStyle = createToggleHoverStyle('off')
              toggleHoverStyle(edge)
              edge.animated = false
              return edge
            }
            return c
          })
        )
      }
    }
  }, [latestSelectedEdge, selectedEdgeRef, setEdges])

  return latestSelectedEdge
}
