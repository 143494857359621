import { memo, useState } from 'react'
import { Position, Handle } from 'reactflow'
import { HomeIcon } from '@heroicons/react/24/outline'
import { Card, Text, Flex, TextInput, Title, Subtitle } from '@tremor/react'

const icon = HomeIcon
const type = 'End'

export { icon, type }
export default memo(function Start({ data: { result } }: any) {
  const [name, setName] = useState('')

  return (
    <Card className="space-y-2">
      <Title>Result</Title>
      <Subtitle>{result ? result : ''}</Subtitle>
      <Flex className="space-x-2 whitespace-nowrap" flexDirection="row">
        <Text>Key</Text>
        <TextInput value={name} onChange={(e: any) => setName(e.value)} />
      </Flex>

      <Handle type="target" position={Position.Top} />
    </Card>
  )
})
