import { memo, useState } from 'react'
import { Position, Handle } from 'reactflow'
import { HomeIcon } from '@heroicons/react/24/outline'
import { Card, Text, Flex, TextInput, DatePicker, Title } from '@tremor/react'

const icon = HomeIcon
const type = 'Start'

export { icon, type }
export default memo(function Start() {
  const [name, setName] = useState('Annualized Weekly')
  const [description, setDescription] = useState('Annualized Weekly')
  const [from, setFrom] = useState(new Date())
  const [to, setTo] = useState(new Date())

  return (
    <Card className="space-y-2">
      <Title>Rule</Title>
      <Flex className="space-x-2 whitespace-nowrap" flexDirection="row">
        <Text>Name</Text>
        <TextInput value={name} onChange={(e: any) => setName(e.value)} />
      </Flex>
      <Flex className="space-x-2 whitespace-nowrap" flexDirection="row">
        <Text>Description</Text>
        <TextInput
          value={description}
          onChange={(e: any) => setDescription(e.value)}
        />
      </Flex>
      <Flex className="space-x-2 whitespace-nowrap" flexDirection="row">
        <Text>From</Text>
        <DatePicker value={from} onValueChange={(e: any) => setFrom(e.value)} />
      </Flex>
      <Flex className="space-x-2 whitespace-nowrap" flexDirection="row">
        <Text>Until</Text>
        <DatePicker value={to} onValueChange={(e: any) => setTo(e.value)} />
      </Flex>

      <Handle type="source" position={Position.Bottom} />
    </Card>
  )
})
