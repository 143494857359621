import { useEffect, useState } from 'react'
import { query } from '../lib/query'

export const usePagination = (url: string) => {
  const [pagination, setPagination] = useState({
    page: 0,
    size: 15,
    total: 0,
    results: []
  })

  // We need to debounce this so we dont trigger too many
  let waiting: any = false
  const fetch = async () => {
    if (waiting === true) return
    waiting = true
    const response = await query(
      `${url}?page=${pagination.page}&size=${pagination.size}`
    )
    const { results, total } = await response.json()
    setPagination((old: any) => Object.assign({}, old, { results, total }))
    waiting = false
  }

  const setPage = (page: number) => {
    setPagination((old: any) => Object.assign({}, old, { page }))
  }

  const previous = () => {
    setPagination((old: any) =>
      Object.assign({}, old, { page: Math.max(pagination.page - 1, 0) })
    )
  }

  const next = () => {
    setPagination((old: any) =>
      Object.assign({}, old, { page: pagination.page + 1 })
    )
  }

  const pages = () => {
    const total = Math.ceil(pagination.total / pagination.size)
    const indexes = Array(total)
      .fill(0)
      .map((v, i) => v + i)

    return indexes.slice(
      Math.min(Math.max(total - 3, 0), Math.max(pagination.page - 1, 0)),
      Math.max(pagination.page + 2, 3)
    )
  }

  useEffect(() => {
    fetch()
  }, [pagination.page])

  return {
    ...pagination,
    setPage,
    previous,
    next,
    pages,
    fetch
  }
}
