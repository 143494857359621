import { Badge, Card, Flex, Title } from '@tremor/react'
import { memo } from 'react'
import { Handle, Position } from 'reactflow'

const DecisionNode = memo(function DecisionNode({ id, data }: any) {
  return (
    <Card>
      <Title>{data.calculation.name}</Title>

      {data?.results && (
        <Flex className="space-x-2" alignItems="start">
          {data.results.map((r: any) => (
            <Badge size="xs" color={r.colour} key={r.id}>
              {r.value}
            </Badge>
          ))}
        </Flex>
      )}

      <Handle type="target" position={Position.Top} />
      <Handle type="source" position={Position.Bottom} />
    </Card>
  )
})
export default DecisionNode
