import { LoaderFunctionArgs, redirect } from 'react-router-dom'

import { getLocalStorageItem } from 'utils/functions'
import { LoginSchema } from './authProvider'

export const LOGIN_KEY = 'ADMIN-UI-LOGIN' as const

export function getLocalStorageLoginItem() {
  return getLocalStorageItem(LOGIN_KEY, LoginSchema)
}

export async function loginLoader() {
  const adminUiLogin = getLocalStorageLoginItem()

  if (!adminUiLogin || !adminUiLogin.isAuthenticated) return null

  return redirect('/')
}

export function protectedLoader({ request }: LoaderFunctionArgs) {
  // If the user is not logged in and tries to access `/protected`, we redirect
  // them to `/login` with a `from` parameter that allows login to redirect back
  // to this page upon successful authentication

  const adminUiLogin = getLocalStorageLoginItem()

  if (!adminUiLogin || !adminUiLogin.isAuthenticated) {
    const params = new URLSearchParams()
    params.set('from', new URL(request.url).pathname)
    return redirect('/login?' + params.toString())
  }

  return null
}
