import { create } from 'zustand'
import zukeeper from 'zukeeper'
import { create as createMathStore, all } from 'mathjs'
interface MathStore {
  mathStore: any
  setMathStore: () => void
}

const fold = (array: any, field: any) =>
  array.reduce((a: any, b: any) => (a += b[field]), 0)

const sum_attr = (array: any, field: any) =>
  array.reduce((a: any, b: any) => a + b[field], 0)

const eq = (a: any, b: any) => a == b
const chad = (a: any, b: any) => a + b

const mathStoreFactory = () => {
  const math = createMathStore(all)
  math.import({
    fold,
    sum_attr,
    eq,
    chad
  })
  return math
}

export const useMathStore = create<MathStore>()(
  zukeeper((set: any) => ({
    mathStore: mathStoreFactory()
  }))
)

// @ts-ignore
window.store = useMathStore
