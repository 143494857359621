import { create } from 'zustand'
import { v4 as uuid } from 'uuid'
import zukeeper from 'zukeeper'
// Define the type for your state
interface VariableStoreState {
  variables: Variable[]
  updateVariable: (variable: any) => void
  replaceVariables: (variable: any) => void
}

type Variable = {
  key: string
  name: string
  value: string
  id: string
}

// Create the store
export const useVariableStore = create<VariableStoreState>()(
  zukeeper((set: any) => ({
    variables: [
      {
        key: 'create',
        name: 'Create a Variable',
        value: 'create',
        id: uuid()
      },
      {
        key: 'result',
        name: 'Previous Function Result',
        value: '',
        id: uuid()
      },
      {
        key: 'test',
        name: 'Test variable added',
        value: 'dumb dumb',
        id: uuid()
      }
    ],
    updateVariable: (variable: any) =>
      set((state: any) => ({
        ...state,
        variables: state.variables.map((v: any) =>
          v.id === variable.id ? { ...v, ...variable } : v
        )
      })),
    replaceVariables: (vv: any[]) =>
      set((state: any) => ({
        variables: [...state.variables, ...vv]
      }))
  }))
)
