import { createContext } from 'react'
import Context from '../components/Personas'

import { types } from './index'

import { useMathStore } from '../stores/MathStore'
const mathStore = useMathStore.getState().mathStore

type Entries<T> = {
  [K in keyof T]: [K, T[K]]
}[keyof T][]

type FunctionDefinition = {
  name: string
  fn: (...args: any[]) => any
  params: number
}
export const functionsList: FunctionDefinition[] = [
  {
    name: '+',
    fn: mathStore.add,
    params: 2
  },
  {
    name: '-',
    fn: mathStore.subtract,
    params: 2
  },
  {
    name: '*',
    fn: mathStore.multiply,
    params: 2
  },
  {
    name: '/',
    fn: mathStore.divide,
    params: 2
  },
  {
    name: 'max',
    fn: mathStore.max,
    params: 2
  },
  {
    name: 'floor',
    fn: mathStore.floor,
    params: 1
  }
]

const nodeDefinitons = [functionsList].flat()

const nodeTypes = Object.assign({}, { Context: Context }, types)

export { nodeDefinitons, nodeTypes }

export default createContext({
  contexts: {}
})
