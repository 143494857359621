import { BarChart, Card, Text, Legend, Flex } from '@tremor/react'

const colors: any = {
  BUFFER: 'transparent',
  GROSS: 'emerald',
  NET: 'yellow',
  PAYE: 'orange',
  KIWI_DED: 'rose',
  KIWI_CONTIRB: 'violet'
}

const categories = [
  'BUFFER',
  'GROSS',
  'NET',
  'PAYE',
  'KIWI_DED',
  'KIWI_CONTRIB'
]

const customTooltip = (props: any) => {
  if (!props.active || !props.payload) return null
  return (
    <Card>
      {props.payload
        .filter((p: any) => p.name != 'BUFFER')
        .map((item: any) => (
          <Flex key={item.name}>
            <Legend categories={[item.name]} colors={[colors[item.name]]} />
            <Text>${item.value.toFixed(2)}</Text>
          </Flex>
        ))}
    </Card>
  )
}

const WaterfallGraph = (props: any) => {
  const itemOrder = ['GROSS', 'NET', 'PAYE', 'KIWI_DED', 'KIWI_CONTRIB']
  const orderedItems = props.items.sort((a: any, b: any) => {
    const aIndex = itemOrder.findIndex(e => a.name == e)
    const bIndex = itemOrder.findIndex(e => b.name == e)
    return aIndex > bIndex ? 1 : -1
  })

  return (
    <Flex flexDirection="col">
      <BarChart
        showAnimation={true}
        className="mt-6"
        showLegend={false}
        stack={true}
        index={'name'}
        data={orderedItems
          .map((c: any, i: any) => {
            // Gross needs to be broken down into all except for kiwi_contrib, kiwi contrib should sit on top
            const buffer = orderedItems
              .slice(0, i)
              .filter((f: any) => f.name != 'GROSS')
              .reduce((a: any, b: any) => (a += b.value), 0)

            // Gross + Gross breakdown
            if (c.name == 'GROSS') {
              const data = Object.fromEntries(
                orderedItems
                  .filter((f: any) => f.name != 'GROSS')
                  .map((f: any) => [f.name, f.value])
              )
              return [
                {
                  [categories[i + 1]]: c.value,
                  BUFFER: buffer
                },
                data
              ]
            }

            return {
              [categories[i + 1]]: c.value,
              BUFFER: buffer
            }
          })
          .flat()}
        customTooltip={customTooltip}
        yAxisWidth={100}
        categories={categories.filter((f: any) => f.name != 'BUFFER')}
        colors={Object.values(colors)}
        valueFormatter={v => v.toFixed(2)}
      />
      <Legend
        categories={categories.filter(f => f != 'BUFFER')}
        colors={Object.values(colors)}
      />
    </Flex>
  )
}
export default WaterfallGraph
