import { useEffect, useState } from 'react'
import {
  Flex,
  Text,
  Title,
  Card,
  Col,
  Grid,
  Icon,
  Subtitle
} from '@tremor/react'
import { XMarkIcon } from '@heroicons/react/24/outline'

import { useNavigate, useParams } from 'react-router-dom'
import { query } from '../../lib/query'
import { format, parseISO } from 'date-fns'

export default function Employment() {
  const { id } = useParams()
  const navigate = useNavigate()

  const [employment, setEmployment]: any = useState('')
  const fetch = async () => {
    const request = await query(`/employment/${id}`)
    const result = await request.json()
    setEmployment(result)
  }
  useEffect(() => {
    fetch()
  }, [id])

  if (!employment) return <></>
  return (
    <Grid className="gap-5" numItems={1} numItemsLg={5}>
      <Col numColSpan={1} numColSpanLg={5}>
        <Flex className="space-x-5">
          <Icon
            icon={XMarkIcon}
            variant="shadow"
            onClick={() => navigate('/')}
          />
          <Flex alignItems="start" flexDirection="col">
            <Title>
              {employment.first_name} {employment.last_name}
            </Title>
            <Subtitle>{employment.job_title}</Subtitle>
          </Flex>
        </Flex>
      </Col>
      <Col className="space-y-5" numColSpan={1} numColSpanLg={2}>
        <Card>
          <Flex className="space-y-5" alignItems="start" flexDirection="col">
            <Title>Settings</Title>
            {Object.entries(employment.settings).map((setting: any) => (
              <Flex key={setting[0]} flexDirection="row" alignItems="start">
                <Text>{setting[0]}</Text>
                <Text>{setting[1]}</Text>
              </Flex>
            ))}
          </Flex>
        </Card>
        <Card>
          <Flex className="space-y-5" alignItems="start" flexDirection="col">
            <Title>Input Data</Title>
            {employment.input.map((input: any) => (
              <Flex
                key={input.payload.date}
                flexDirection="row"
                alignItems="start"
              >
                <Text>
                  {format(parseISO(input.payload.date), 'dd/MM/yyyy')}
                </Text>
                <Text>{input.payload.hours} hours</Text>
              </Flex>
            ))}

            {/* <Button
              style={{ marginLeft: 'auto' }}
              icon={BoltIcon}
              onClick={calculate}
              loading={calculating}
            >
              Calculate
            </Button> */}
          </Flex>
        </Card>
      </Col>
      {/* <Col className="space-y-5" numColSpan={1} numColSpanLg={3}>
        {employment.calcs && (
          <CalculationCard
            items={employment.calcs.map((c: any) => ({
              name: c.code,
              value: c.amount
            }))}
          />
        )}
      </Col> */}
    </Grid>
  )
}
