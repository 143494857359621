import { createContext, useState } from 'react'

// Global state provider for nodes inside of React Flow
// State will be formed as a tree, with each branch being namespaced by the parent node's id (guid).
export const StateContext = createContext({
  state: {},
  setState: (...args: any[]): any => {
    console.log({ args })
  }
})

export const StateProvider = ({ children }: any) => {
  const [state, setState] = useState({})

  return (
    <StateContext.Provider value={{ state, setState }}>
      {children}
    </StateContext.Provider>
  )
}
