import { Icon, Color } from '@tremor/react'
import { Square2StackIcon } from '@heroicons/react/24/solid'

import OptionPicker from './OptionPicker'

export const colours: Color[] = [
  'slate',
  'gray',
  'zinc',
  'neutral',
  'stone',
  'red',
  'orange',
  'amber',
  'yellow',
  'lime',
  'green',
  'emerald',
  'teal',
  'cyan',
  'sky',
  'blue',
  'indigo',
  'violet',
  'purple',
  'fuchsia',
  'pink',
  'rose'
]

type ColourPickerProps = {
  value: Color
  onValueChange: (value: Color) => void
}

const ColourPicker = ({ value, onValueChange }: ColourPickerProps) => {
  return (
    <OptionPicker
      colour={value}
      value={value}
      onValueChange={onValueChange}
      options={colours}
      render={(colour, select) => (
        <Icon
          key={colour}
          className="m-1"
          icon={Square2StackIcon}
          variant="shadow"
          color={colour}
          tooltip={colour}
          onClick={() => select(colour)}
        />
      )}
    />
  )
}
export default ColourPicker
