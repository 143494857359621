import { useState } from 'react'
import {
  Card,
  Title,
  Callout,
  Button,
  Dialog,
  DialogPanel,
  Switch,
  ProgressBar
} from '@tremor/react'
import { query } from '../../lib/query'

interface ImportDialogProps {
  isOpen: boolean
  onClose: () => void
}

const importRuleset = async (
  newRuleset: any,
  deleteOldRuleset: boolean,
  onUpdate: (processedItems: number, totalItemsToProcess: number) => void,
  onFinish: () => void
) => {
  let totalActionCount =
    newRuleset.data.rules.length + newRuleset.data.decisions.length
  let currentActionCount = 0
  if (deleteOldRuleset) {
    // Get the results and the count
    const ruleRequest = await (
      await query(`/calculation?page_size=99999`)
    ).json()
    const decisionRequest = await (
      await await query(`/decision?page_size=99999`)
    ).json()
    totalActionCount =
      totalActionCount +
      ruleRequest.results.length +
      decisionRequest.results.length
    const oldRules = ruleRequest.results
    const oldDecisions = decisionRequest.results

    // Purging the old ruleset
    for (const decision of oldDecisions) {
      await query('/decision/' + decision.id, { method: 'DELETE' })
      currentActionCount++
      onUpdate(currentActionCount, totalActionCount)
    }
    for (const rule of oldRules) {
      await query('/calculation/' + rule.id, { method: 'DELETE' })
      currentActionCount++
      onUpdate(currentActionCount, totalActionCount)
    }
  }

  // Need a lookup of calculations so can remap decision source/target IDs against the newly created IDs for rules
  const importedRuleLookup: any = {}

  for (const rule of newRuleset.data.rules) {
    // Will also insert the rule at the same time
    const oldID = rule.id
    delete rule.id
    const serverRule = await (
      await query('/calculation', {
        method: 'post',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(rule)
      })
    ).json()
    importedRuleLookup[oldID] = serverRule.id

    currentActionCount++
    onUpdate(currentActionCount, totalActionCount)
  }

  for (const decision of newRuleset.data.decisions) {
    delete decision.id
    decision.source_id = importedRuleLookup[decision.source_id]
    decision.target_id = importedRuleLookup[decision.target_id]
    await query('/decision', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(decision)
    })

    currentActionCount++
    onUpdate(currentActionCount, totalActionCount)
  }

  // The import has completed
  onFinish()
}

const ImportDialog = (props: ImportDialogProps) => {
  const [isDeleteOn, setIsDeleteOn] = useState(false)
  const [isInProgress, setIsInProgress] = useState(false)
  const [progressCompletionPercentage, setProgressCompletionPercentage] =
    useState(0)
  const [pendingImportData, setPendingImportData] = useState(null)

  const handleFileChange = async (e: any) => {
    const rawFile = e.nativeEvent.target.files[0]
    if (rawFile === undefined) {
      setPendingImportData(null)
      return
    }
    setPendingImportData(JSON.parse(await rawFile.text()))
  }

  // Updates the progress bar
  const updateCompletionPercentage = (
    processedItems: number,
    totalItemsToProcess: number
  ) => {
    setProgressCompletionPercentage(
      Math.floor((processedItems / totalItemsToProcess) * 100)
    )
  }

  const startImport = () => {
    if (pendingImportData === null) {
      return
    }
    setProgressCompletionPercentage(0)
    setIsInProgress(true)
    importRuleset(
      pendingImportData,
      isDeleteOn,
      (num, total) => updateCompletionPercentage(num, total),
      () => {
        setIsInProgress(false)
        props.onClose()
        // The orchestration page needs to get the new data, refreshing the entire page is the easiest way
        window.location.reload()
      }
    )
  }

  return (
    <Dialog open={props.isOpen} static={true} onClose={() => props.onClose()}>
      <DialogPanel>
        <Title>Import Ruleset</Title>
        <Card>
          <Callout title="Important Note" color="yellow">
            This import will alter the ruleset on the server and cannot be
            reverted.
          </Callout>
          <div className="my-2">
            <input
              type="file"
              name="import-file"
              id="import-file"
              accept="application/json"
              onChange={handleFileChange}
            />
          </div>
          <div className="flex items-center space-x-3 my-2">
            <Switch
              id="delete-switch"
              name="delete-switch"
              checked={isDeleteOn}
              onChange={val => setIsDeleteOn(val)}
            />
            <label htmlFor="delete-switch">
              Remove existing rules and decisions
            </label>
          </div>
          {isDeleteOn ? (
            <Callout title="Replace ruleset" color="yellow">
              Imported rules and decisions will replace the existing ruleset.
            </Callout>
          ) : (
            <Callout title="Append ruleset" color="teal">
              Imported rules and decisions will be added to the existing
              ruleset.
            </Callout>
          )}
        </Card>
        <Card>
          {isInProgress ? (
            <ProgressBar
              value={progressCompletionPercentage}
              className="mb-4"
            ></ProgressBar>
          ) : (
            ''
          )}
          <Button
            className="mr-1"
            onClick={startImport}
            disabled={pendingImportData === null || isInProgress}
          >
            Import
          </Button>
          <Button onClick={props.onClose} disabled={isInProgress}>
            Cancel
          </Button>
        </Card>
      </DialogPanel>
    </Dialog>
  )
}

export default ImportDialog
