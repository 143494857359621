import { create } from 'zustand'
import zukeeper from 'zukeeper'
interface StateStore {
  state: object[]
  anything: object
  setState: (val: any) => void
}
export interface Position {
  x: number
  y: number
}
export interface CustomNode {
  id: string
  type: string
  position: Position
  width: number | null
  height: number | null
  state: (string | number)[]
  operation: string
}
export interface StateObject {
  [key: string]: {
    '0': string | undefined
    '1': string | undefined
  }
}
export const useStateStore = create<StateStore>()(
  zukeeper((set: any) => ({
    state: {},
    anything: { test: 'test' },
    setState: (val: any) =>
      set((state: any) => ({ state: { ...state.state, ...val } }))
  }))
)

// @ts-ignore
window.store = useStateStore
