import { useRef, useCallback, useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import ReactFlow, {
  useNodesState,
  useEdgesState,
  addEdge,
  useReactFlow,
  Controls,
  Background,
  Panel,
  BackgroundVariant,
  Edge
} from 'reactflow'
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionList,
  Button
} from '@tremor/react'
import NodeContext, { nodeDefinitons, nodeTypes } from '../../nodes/NodeContext'
import { UserPlusIcon } from '@heroicons/react/24/outline'
import { v4 as uuid } from 'uuid'
import { RuleContext } from '../../nodes/RuleContext'
import NodeContextMenu from '../../components/NodeContextMenu'
import usePersonaStore from '../../stores/PersonaStore'
import { useVariableStore } from '../../stores/VariableStore'
import { StateObject, useStateStore } from '../../stores/StateStore'
import { useMathStore } from '../../stores/MathStore'
import {
  AssignmentNode,
  ConstantNode,
  MathNode,
  OperatorNode,
  SymbolNode,
  parse
} from 'mathjs'
import { create, all } from 'mathjs'
import { useParams } from 'react-router-dom'
import { query } from '../../lib/query'
import { fitViewOptions } from '.'
import { DraggableNode } from './DraggableNode'
import {
  updateRuleInDatabase,
  createRuleInDatabase,
  transformRule,
  initialNodes
} from './helperFunctions'
import InlineTextInput from '../../components/InlineTextInput'
import toast from 'react-hot-toast'

export const ReactFlowDiagram = () => {
  const { id } = useParams()

  const fetchCalculation = async () => {
    if (id === undefined) {
      // A blank rule
      setRuleName('')
      setRule('')
      setEdges([])
      setNodes([...initialNodes])
      return
    }
    const request = await query(`/calculation/${id}`)
    const result = await request.json()
    const [nodeArray, edgesArray] = transformRule(result.expression)
    setRuleName(result.name ?? '')
    setRule(result.expression ?? '')
    if (edgesArray !== undefined) {
      edgesArray.forEach((edge: any) => {
        setEdges(edges => addEdge(edge, edges))
      })
    }
    if (nodeArray === undefined) {
      setNodes([...initialNodes])
    }
    else {
      setNodes([...initialNodes, ...nodeArray!])
    }
  }
  useEffect(() => {
    fetchCalculation()
  }, [id])

  const reactFlowWrapper = useRef<any>(null)
  const dragRef = useRef<any>(null)
  const ref = useRef<any>()
  const initialEdges: any[] = []

  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes)
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges)
  const [collision, setCollision] = useState<boolean>(false)
  const [targetID, setTargetID] = useState<string>('')
  const [transitID, setTransitID] = useState<string>('')
  const [target, setTarget] = useState<any>()
  const [menu, setMenu] = useState<any>(null)
  const [rule, setRule] = useState('')
  const [ruleName, setRuleName] = useState('')
  const { setState }: any = useStateStore()
  const { replaceVariables } = useVariableStore()
  const { contexts } = usePersonaStore()

  const { functions } = useContext(RuleContext)
  const { project } = useReactFlow()
  const onConnect = useCallback(
    (params: any) => setEdges(eds => addEdge(params, eds)),
    []
  )
  const onPaneClick = useCallback(() => setMenu(null), [setMenu])
  const navigate = useNavigate()

  useEffect(() => {
    if (!collision) {
      return
    }
    setCollision(false)
    const targetNode = nodes.find(node => node.id === targetID)
    setNodes(nodes =>
      nodes.map((n: any) => {
        if (n.id === transitID) {
          if (targetNode && targetNode.height)
            n.position = {
              ...n.position,
              x: targetNode?.position.x,
              y: targetNode?.position.y + targetNode.height + 50
            }
        }
        return n
      })
    )
  }, [collision])

  const onEdgeDoubleClick = (event: React.MouseEvent, edge: Edge) => {
    setEdges((edges: Edge[]) => {
      return edges.filter((x: Edge) => x.source !== edge.source)
    })
  }

  const onNodeContextMenu = useCallback(
    (event: any, node: any) => {
      // Prevent native context menu from showing
      event.preventDefault()
      // Calculate position of the context menu
      const pane = ref.current.getBoundingClientRect()
      setMenu({
        id: node.id,
        top: node.position.y,
        left: node.position.x + node.width + 10
      })
    },
    [setMenu]
  )

  const onNodeDrag = (event: any, node: any) => {
    // calculate the center point of the node being dragged from position and dimensions
    const Top = {
      x: node.position.x + node.width / 2,
      y: node.position.y - node.height
    }
    const Bottom = { x: node.position.x + node.width / 2, y: node.position.y }
    //find a node where the center point is inside
    const targetNode = nodes.find(
      (n: any) =>
        (Top.x > n.position.x &&
          Top.x < n.position.x + n.width! &&
          Top.y > n.position.y &&
          Top.y < n.position.y + n.height!) ||
        (Bottom.x > n.position.x &&
          Bottom.x < n.position.x + n.width! &&
          Bottom.y > n.position.y - n.height! &&
          Bottom.y < n.position.y + n.height! &&
          n.id !== node.id) // this is needed, otherwise we would always find the dragged node
    )
    setTarget(targetNode)
  }

  const onNodeDragStop = (evt: any, node: any) => {
    // on drag stop, we want to position the dragged node either above or below the target node
    const Top = {
      x: node.position.x + node.width / 2,
      y: node.position.y - node.height
    }
    const Bottom = { x: node.position.x + node.width / 2, y: node.position.y }
    // const targetColor = target?.data.label;
    setNodes(nodes =>
      nodes.map(n => {
        if (n.id === node.id && target) {
          if (
            Top.x > target?.position.x &&
            Top.x < target?.position.x + target?.width &&
            Top.y < target?.position.y &&
            Top.y > target?.position.y - target?.height / 2
          ) {
            n.position = {
              ...n.position,
              x: target?.position.x,
              y: target?.position.y + target.height + 50
            }
            setEdges((edges: any) => [
              ...edges,
              { source: target.id, target: node.id }
            ])
          } else if (
            Bottom.x > target?.position.x &&
            Bottom.x < target?.position.x + target?.width &&
            Bottom.y > target?.position.y - target?.height &&
            Bottom.y < target?.position.y - target?.height / 2
          ) {
            n.position = {
              ...n.position,
              x: target?.position.x,
              y: target?.position.y - target.height - 50
            }
            setEdges((edges: any) => [
              ...edges,
              { source: node.id, target: target.id }
            ])
          }
        }
        return n
      })
    )

    setTarget(null)
    dragRef.current = null
  }

  const onDragOver = useCallback((event: any) => {
    event.preventDefault()
    event.dataTransfer.setData('application/reactflowid', uuid())
    event.dataTransfer.dropEffect = 'move'
  }, [])

  // This drop has a lot of logic that we will want down the chain
  const onDrop = useCallback(
    (event: any) => {
      event.preventDefault()

      if (!reactFlowWrapper.current) return
      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect()
      const type = event.dataTransfer.getData('application/reactflow')
      const name = event.dataTransfer.getData('application/reactflowname')
      const id = event.dataTransfer.getData('application/reactflowid')

      // check if the dropped element is valid
      if (typeof type === 'undefined' || !type) return

      const position = project({
        x: event.clientX - reactFlowBounds.left - 75,
        y: event.clientY - reactFlowBounds.top - 75
      })

      const newNode = {
        id: id,
        type,
        position,
        data: {
          onDrop: (event: any) => {
            // id is the ID of node being dropped onto
            const transit = event.dataTransfer.getData(
              'application/reactflowid'
            )
            setTargetID(id)
            setTransitID(transit)
            setCollision(true)
            setEdges((edges: any) => [
              ...edges,
              { source: id, target: transit }
            ])
          },
          node: nodeDefinitons.find((f: any) => f.name == name)
        }
      }
      setState({
        [id]: {}
      })
      setNodes(nds => nds.concat(newNode))
    },
    [project]
  )

  useEffect(() => {
    // We are setting the nodes but it is not re-loading
    setNodes((nodes: any) => {
      const index = nodes.findIndex((n: any) => n.type == 'Context')
      nodes[index].data.contexts = contexts
      return [...nodes]
    })

    // update variables as well
    const vars = contexts
      .map((c: any) => c.properties)
      .flat()
      .map((c: any) => [c.name, c.value])
      .map((e: any) => ({
        name: e[0],
        key: e[0]
      }))
    replaceVariables(vars)
  }, [contexts])

  const handleImportClick = () => {
    const [nodesArray, edgesArray] = transformRule(rule)
    edgesArray.forEach((edge: any) => {
      setEdges(edges => addEdge(edge, edges))
    })
    setNodes([...initialNodes, ...nodesArray!])
  }

  const handleRawRuleUpdate = (newRuleValue: string) => {
    setRule(newRuleValue)

    const [nodesArray, edgesArray] = transformRule(newRuleValue)
    edgesArray.forEach((edge: any) => {
      setEdges(edges => addEdge(edge, edges))
    })
    setNodes([...initialNodes, ...nodesArray!])
  }

  const validateRawRuleUpdate = (newRuleValue: string) => {
    // TODO: attempt for basic correctness
    // Note: it's pretty hard to break mathjs, parse will normally give back something
    if (newRuleValue === '') {
      toast('Rule expression must have a value')
      return false
    }
    return true
  }

  const saveRuleToDatabase = async () => {
    if (!rule || !ruleName) {
      // Rule is empty
      toast('Rules must have a name and expression')
      return
    }
    if (id === undefined) {
      // Saving a new rule
      const details = await createRuleInDatabase(rule, ruleName)

      toast('New Rule created')
      // This loads the page again with the correct URL and thus correct `id` parameter
      navigate('/rules/' + details.id)
    }
    else {
      // Updating an existing rule
      await updateRuleInDatabase(rule, ruleName, id)
      toast('Rule saved')
    }
  }

  return (
    <NodeContext.Provider value={{ contexts: contexts }}>
      <div className='flex'>
        <div className='flex-auto p-2'>
          <InlineTextInput 
            prefix="Name" 
            value={ruleName} 
            placeholder="Enter a rule name" 
            onEditClose={value => setRuleName(value)}></InlineTextInput>
        </div>
        <div className='flex-auto p-2'>
          <InlineTextInput 
            prefix="Rule" 
            value={rule} 
            placeholder="Enter raw rule expression" 
            onEditValidate={value => validateRawRuleUpdate(value)}
            onEditClose={value => handleRawRuleUpdate(value)}></InlineTextInput>
        </div>
        <div className='flex-auto grow-0 px-2 py-7 basis-2'>
          <Button onClick={()=>saveRuleToDatabase()}>Save</Button>
        </div>
      </div>
      <div style={{ width: '100%', height: '100%' }} ref={reactFlowWrapper}>
        <ReactFlow
          ref={ref}
          proOptions={{ hideAttribution: true }}
          nodes={nodes}
          onEdgeDoubleClick={onEdgeDoubleClick}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          onDrop={onDrop}
          onDragOver={onDragOver}
          onNodeDrag={onNodeDrag}
          onNodeDragStop={onNodeDragStop}
          nodeTypes={nodeTypes}
          fitViewOptions={fitViewOptions}
          onPaneClick={onPaneClick}
          onNodeContextMenu={onNodeContextMenu}
        >
          <Controls position="bottom-right" />
          <Background variant={BackgroundVariant.Dots} gap={20} size={1.5} />

          {/* Available drag and drop components */}
          <Panel position="top-left">
            <AccordionList
              style={{
                maxHeight: '97vh',
                minWidth: '250px',
                overflowY: 'scroll'
              }}
            >
              <Accordion>
                <AccordionHeader>Utilities</AccordionHeader>
                <AccordionBody className="space-y-2">
                  <DraggableNode
                    key={'Start'}
                    icon={UserPlusIcon}
                    node_type={'Start'}
                    node_name={'Start'}
                  />
                  <DraggableNode
                    key={'End'}
                    icon={UserPlusIcon}
                    node_type={'End'}
                    node_name={'End'}
                  />
                </AccordionBody>
              </Accordion>
              <Accordion>
                <AccordionHeader>Values</AccordionHeader>
                <AccordionBody className="space-y-2">
                  <DraggableNode
                    key={'Variable'}
                    icon={UserPlusIcon}
                    node_type={'Variable'}
                    node_name={'Variable'}
                  />
                </AccordionBody>
              </Accordion>
              <Accordion>
                <AccordionHeader>Functions</AccordionHeader>
                <AccordionBody className="space-y-2">
                  {Object.values(functions).map((n: any) => (
                    <DraggableNode
                      key={n.name}
                      icon={UserPlusIcon}
                      node_type={'Function'}
                      node_name={n.name}
                    />
                  ))}
                </AccordionBody>
              </Accordion>
            </AccordionList>
          </Panel>
          {menu && <NodeContextMenu onClick={onPaneClick} {...menu} />}
        </ReactFlow>
      </div>
    </NodeContext.Provider>
  )
}
