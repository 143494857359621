import { useState, useRef } from 'react'
import { Card, Flex, Icon, Color, IconProps } from '@tremor/react'
import { EyeDropperIcon } from '@heroicons/react/24/solid'

type ChooseType<T, U extends { type: T }> = T extends Color ? T : U
type OptionPickerProps<T, U extends { type: T }> = {
  icon?: IconProps['icon']
  value: T
  onValueChange: (value: ChooseType<T, U>) => void
  options: ChooseType<T, U>[]
  render: (
    option: ChooseType<T, U>,
    select: (option: ChooseType<T, U>) => void
  ) => JSX.Element
  colour?: Color
}

function OptionPicker<T, U extends { type: T }>({
  icon = EyeDropperIcon,
  value,
  onValueChange,
  options,
  render,
  colour
}: OptionPickerProps<T, U>) {
  const focus = useRef<HTMLDivElement>(null)
  const [showing, setShowing] = useState<boolean>(false)

  const show = () => {
    setShowing(true)
    focus.current?.focus()
  }
  const hide = () => setShowing(false)
  const select = (option: ChooseType<T, U>) => {
    onValueChange(option)
    hide()
  }

  return (
    <Flex ref={focus} onBlur={hide} tabIndex={1} className="relative w-max">
      <Icon
        variant="shadow"
        color={colour}
        icon={icon}
        tooltip={value as string}
        onClick={show}
      />

      <Card
        className={`absolute p-2 top-10 z-10 w-max grid grid-cols-5 ${
          !showing && 'hidden'
        }`}
      >
        {options.map(option => render(option, select))}
      </Card>
    </Flex>
  )
}
export default OptionPicker
