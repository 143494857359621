import * as Constant from './Constant'
import * as Variable from './Variable'
import * as Start from './Start'
import * as End from './End'
import * as FunctionNode from './Function'

// Types array for react flow
const types: any = {
  End: End.default,
  Constant: Constant.default,
  Variable: Variable.default,
  Start: Start.default,
  Function: FunctionNode.default
}
export { types }

export default {
  End,
  Start,
  Constant,
  Variable,
  Function: FunctionNode
}
