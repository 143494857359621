'use client'

import React, { useState, useEffect } from 'react'
import {
  Card,
  Title,
  Button,
  NumberInput,
  ProgressCircle,
  Flex,
  Subtitle,
  Text,
  Grid,
  Col,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Icon,
  Badge,
  TableHead,
  TableHeaderCell,
  Legend
} from '@tremor/react'
import { PlusIcon, UserIcon } from '@heroicons/react/24/outline'

import { query } from '../lib/query'
import Sidebar from '../components/Sidebar'
import CalculationCard from '../components/CalculationCard'

import { io } from 'socket.io-client'
const socket = io(`ws://localhost:8080`, {})

export default function DashboardExample() {
  const [payrun, setPayrun] = useState({
    guid: '',
    total: 0,
    complete: 0
  })
  const [analytics, setAnalytics] = useState<any>({ total: 0, complete: 0 })
  const [calculating, setCalculating] = useState(false)
  const [polling, setPolling] = useState(false)

  useEffect(() => {
    socket.on('progress', event => {
      console.log(event)
    })
  }, [])

  // Trigger calculation
  const run = async () => socket.emit('calculate')

  // Track seeding progress
  useEffect(() => {
    socket.on('refresh', async result => {
      setAnalytics(result)
    })
  }, [])

  const [taxCodes, setTaxCodes] = useState([
    { code: 'M', value: 0.2 },
    { code: 'ME', value: 0.3 },
    { code: 'M SL', value: 0.25 },
    { code: 'ME SL', value: 0.35 }
  ])
  const updateTaxCode = (event: any, taxCode: any) => {
    setTaxCodes(oldCodes => {
      const index = oldCodes.findIndex(c => c.code == taxCode.code)
      const newCodes = [...oldCodes]
      newCodes[index] = { code: taxCode.code, value: event.target.value }
      return newCodes
    })
  }

  // API
  const [employees, setEmployees] = useState([])

  // We need this to return the employment settings as well
  const fetchEmployees = async () => {
    try {
      const response = await query(`/workbench/employees?page=0&size=5`)
      if (!response.ok) throw Error(await response.text())
      const { rows } = await response.json()
      const preview = rows.filter((r: any) => r.calculations)
      for (const row of preview)
        row.calculations.sort((a: any, b: any) =>
          a.amount > b.amount ? -1 : 1
        )
      setEmployees(preview)
    } catch (e) {
      console.error(e)
    }
  }

  const colours = [
    'bg-emerald-500',
    'bg-yellow-500',
    'bg-orange-500',
    'bg-rose-500',
    'bg-violet-500'
  ]

  const itemOrder = ['GROSS', 'NET', 'PAYE', 'KIWI_DED', 'KIWI_CONTRIB']
  const orderItems = (items: any) =>
    items.sort((a: any, b: any) => {
      const aIndex = itemOrder.findIndex(e => a.type_code == e)
      const bIndex = itemOrder.findIndex(e => b.type_code == e)
      return aIndex > bIndex ? 1 : -1
    })

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: 'max-content 1fr',
        gridTemplateRows: 'min-content 1fr',
        gridTemplateAreas: `
          'nav header'
          'nav content'
        `,
        height: '100vh',
        overflow: 'hidden'
      }}
    >
      <Sidebar />
      <Grid
        style={{ gridArea: 'content' }}
        className="p-12 overflow-auto pb-12 gap-5"
        numItems={1}
        numItemsSm={1}
        numItemsLg={3}
      >
        <Col className="space-y-5">
          <Card>
            <Flex className="space-x-5" justifyContent="start">
              <ProgressCircle
                showAnimation={false}
                value={(100 / analytics.total) * analytics.complete || 0}
                size="lg"
              >
                <Title>
                  {((100 / analytics.total) * analytics.complete || 0).toFixed(
                    0
                  )}
                  %
                </Title>
              </ProgressCircle>

              <Flex flexDirection="col" alignItems="start">
                <Text>Calculations</Text>
                <Title>
                  {analytics.complete || 0} / {analytics.total || 0}
                </Title>
                {/* <Subtitle>${(analytics?.GROSS || 0).toFixed(2)}</Subtitle> */}
              </Flex>
            </Flex>
          </Card>

          <Card>
            <Title>Tax Codes</Title>
            {taxCodes.map(t => (
              <Flex
                key={t.code}
                className="space-x-5 space-y-2 whitespace-nowrap"
              >
                <Text className="w-24">{t.code}</Text>
                <NumberInput
                  placeholder={t.code}
                  value={t.value}
                  max={1}
                  min={0.05}
                  step={'0.05'}
                  onChange={e => updateTaxCode(e, t)}
                />
              </Flex>
            ))}

            <Button
              className="mt-5"
              loading={calculating}
              icon={PlusIcon}
              onClick={run}
            >
              Calculate Pay
            </Button>
          </Card>
        </Col>
        <Col className="space-y-5" numColSpan={1} numColSpanLg={2}>
          <CalculationCard
            items={Object.entries(analytics)
              .filter(e => !['total', 'complete'].includes(e[0]))
              .map(e => ({
                name: e[0],
                value: e[1]
              }))}
          />
        </Col>

        <Col className="space-y-5" numColSpan={1} numColSpanLg={3}>
          {employees.length != 0 && (
            <Card>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableHeaderCell></TableHeaderCell>
                    <TableHeaderCell></TableHeaderCell>
                    <TableHeaderCell>Tax Code</TableHeaderCell>
                    <TableHeaderCell>
                      <Legend categories={['GROSS']} colors={['emerald']} />
                    </TableHeaderCell>
                    <TableHeaderCell>
                      <Legend categories={['NET']} colors={['yellow']} />
                    </TableHeaderCell>
                    <TableHeaderCell>
                      <Legend categories={['PAYE']} colors={['orange']} />
                    </TableHeaderCell>
                    <TableHeaderCell>
                      <Legend categories={['KIWI DED']} colors={['rose']} />
                    </TableHeaderCell>
                    <TableHeaderCell>
                      <Legend
                        categories={['KIWI CONTRIB']}
                        colors={['violet']}
                      />
                    </TableHeaderCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {employees.map((e: any) => (
                    <>
                      <TableRow key={e.payroll_employment_id}>
                        <TableCell style={{ width: '40px' }}>
                          <Icon icon={UserIcon} variant="solid" size="md" />
                        </TableCell>
                        <TableCell className="text-left">
                          <Text>
                            {e.first_name} {e.last_name}
                          </Text>
                          <Subtitle>{e.job_title}</Subtitle>
                        </TableCell>
                        <TableCell>
                          {
                            e.settings.find((s: any) => s.type == 'IRD')
                              ?.properties?.taxCode
                          }
                        </TableCell>
                        {orderItems(e.calculations).map((e: any) => (
                          <TableCell key={e.id}>${e}</TableCell>
                        ))}
                      </TableRow>
                      <div
                        style={{ display: 'table-row' }}
                        className="w-full"
                        key={`bar-${e.payroll_employment_id}`}
                      >
                        <td className="w-full" colSpan={8}>
                          <Flex>
                            {e.calculations.map((e: any, i: any) => (
                              <Badge
                                key={i}
                                className={colours[i]}
                                style={{
                                  minHeight: '8px',
                                  flex: e.amount,
                                  borderRadius: 0
                                }}
                                tooltip={`${e.type_code}: $${e.amount}`}
                              />
                            ))}
                          </Flex>
                        </td>
                      </div>
                    </>
                  ))}
                </TableBody>
              </Table>
            </Card>
          )}
        </Col>
      </Grid>
    </div>
  )
}
