import { create } from 'zustand'
import { v4 as uuid } from 'uuid'
import { faker } from '@faker-js/faker'

import { colours } from '../components/ColourPicker'
import zukeeper from 'zukeeper'

import { ContextType, Property } from 'features/contexts'

interface PersonaStore {
  contexts: ContextType[]
  updateContext: (context: ContextType, obj: Partial<ContextType>) => void
  addProperty: (contextId: ContextType['id']) => void
  deleteContext: (contextId: ContextType['id']) => void
  duplicateContext: (contextIndex: number) => void
  addContext: () => void
  updateProperty: (
    contextId: ContextType['id'],
    propertyId: Property['id'],
    change: Partial<Property>
  ) => void
  deleteProperty: (
    contextId: ContextType['id'],
    propertyId: Property['id']
  ) => void
}

const usePersonaStore = create<PersonaStore>()(
  zukeeper((set: any) => ({
    contexts: [
      {
        id: uuid(),
        name: 'Ann Brekke',
        colour: 'blue',
        properties: [
          {
            id: uuid(),
            name: 'frequency',
            value: 'weekly',
            type: 'text'
          },
          {
            id: uuid(),
            name: 'gross_pay',
            value: 750,
            type: 'text'
          },
          {
            id: uuid(),
            name: 'tax_code',
            value: 'M SL',
            type: 'text'
          },
          {
            id: uuid(),
            name: 'student_load_deduction_rate',
            value: 0.11,
            type: 'number'
          },
          {
            id: uuid(),
            name: 'kiwisaver_contrib',
            value: 0.05,
            type: 'text'
          },
          {
            id: uuid(),
            name: 'kiwisaver_ded',
            value: 0.04,
            type: 'text'
          }
        ],
        results: []
      },
      {
        id: uuid(),
        name: 'Carmen Jones',
        colour: 'red',
        properties: [
          {
            id: uuid(),
            name: 'frequency',
            value: 'fortnightly',
            type: 'text'
          },
          {
            id: uuid(),
            name: 'gross_pay',
            value: 2750,
            type: 'text'
          },
          {
            id: uuid(),
            name: 'tax_code',
            value: 'M SL',
            type: 'text'
          },
          {
            id: uuid(),
            name: 'student_load_deduction_rate',
            value: 0.11,
            type: 'number'
          },
          {
            id: uuid(),
            name: 'kiwisaver_contrib',
            value: 0.05,
            type: 'text'
          },
          {
            id: uuid(),
            name: 'kiwisaver_ded',
            value: 0.04,
            type: 'text'
          }
        ],
        results: []
      }
    ],
    updateContext: ({ id }: any, obj: any) =>
      set((state: any) => {
        const contexts = state.contexts.map((context: any) =>
          context.id === id ? { ...context, ...obj } : context
        )
        return { contexts }
      }),
    updateProperty: (contextId: any, propertyId: any, change: any) =>
      set((state: any) => {
        const updatedContexts = state.contexts.map((context: any) =>
          context.id === contextId
            ? {
                ...context,
                properties: context.properties.map((property: any) =>
                  property.id === propertyId
                    ? { ...property, ...change }
                    : property
                )
              }
            : context
        )
        return { contexts: updatedContexts }
      }),
    addContext: () =>
      set((state: any) => {
        const newContext: ContextType = {
          id: uuid(),
          name: faker.person.fullName(),
          colour: faker.helpers.arrayElement(colours), // default color, replace as needed
          properties: [
            {
              id: uuid(),
              name: '',
              value: '',
              type: faker.helpers.arrayElement([
                'number',
                'text',
                'date',
                'range'
              ])
            }
          ],
          results: []
        }
        const updatedContexts = [...state.contexts, newContext]
        return { contexts: updatedContexts }
      }),
    deleteContext: (contextId: any) =>
      set((state: any) => ({
        ...state,
        contexts: state.contexts.filter((cur: any) => cur.id !== contextId)
      })),
    duplicateContext: (contextIndex: any) =>
      set((state: any) => {
        const context = state.contexts[contextIndex]
        const id = uuid()
        const updatedContexts = [
          ...state.contexts.slice(0, contextIndex + 1),
          {
            ...context,
            id,
            name: context.name + ' copy',
            properties: context.properties.map((p: any) => ({ ...p })),
            results: context.results.map((r: any) => ({ ...r }))
          },
          ...state.contexts.slice(contextIndex + 1)
        ]

        return { contexts: updatedContexts }
      }),

    addProperty: (contextId: any) =>
      set((state: any) => {
        const newProperty: Property = {
          id: uuid(),
          name: '',
          value: '',
          type: 'text'
        }
        const updatedContexts = state.contexts.map((context: any) =>
          context.id === contextId
            ? {
                ...context,
                properties: [...context.properties, newProperty]
              }
            : context
        )
        return { contexts: updatedContexts }
      }),
    deleteProperty: (contextId: any, propertyId: any) =>
      set((state: any) => ({
        ...state,
        contexts: state.contexts.map((context: any) =>
          context.id === contextId
            ? {
                ...context,
                properties: context.properties.filter(
                  (property: any) => property.id !== propertyId
                )
              }
            : context
        )
      }))
  }))
)

export default usePersonaStore
// @ts-ignore
window.store = usePersonaStore
