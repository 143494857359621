import { useMathStore } from '../../stores/MathStore'
const mathStore = useMathStore.getState().mathStore
export type Calculation = {
  id?: number
  name: string
  description: string
  expression: string
  function: any
  active_from: Date
  active_until?: Date
  created_at: Date
  updated_at: Date
}

export type Decision = {
  id?: number
  description: string
  expression: string
  function: any
  source_id: number
  target_id: number
  active_from: Date
  active_until?: Date
  created_at: Date
  updated_at: Date
}

class Engine {
  calculations: Calculation[] = []
  decisions: any[] = []
  mappedDecisions: any = new Map()

  constructor(calculations: Calculation[], decisions: Decision[]) {
    for (const calculation of calculations)
      calculation.function = mathStore.parse(calculation.expression)
    for (const decision of decisions) {
      decision.function = mathStore.parse(decision.expression)
    }

    this.calculations = calculations
    this.decisions = decisions
    this.mappedDecisions = decisions.reduce(
      (a: any, b: Decision) => {
        if (!a.source.has(b.source_id)) a.source.set(b.source_id, [])
        if (!a.target.has(b.target_id)) a.target.set(b.target_id, [])
        a.source.get(b.source_id).push(b)
        a.target.get(b.target_id).push(b)
        return a
      },
      { source: new Map(), target: new Map() }
    )
  }

  evaluate = (persona: any) => {
    const root = this.calculations.find(
      (c: any) => !this.mappedDecisions.target.has(c.id)
    )
    return this.traverse(root, persona)
  }

  private traverse = (calculation: any, persona: any, audit: any = []): any => {
    // Calculation result and audit
    const result = calculation.function.evaluate(persona)
    audit.push({
      id: calculation.id,
      name: calculation.name,
      description: calculation.description,
      result
    })
    // Follow decisions or return if none
    const current_decisions = (
      this.mappedDecisions.source.get(calculation.id) || []
    ).filter((d: Decision) => d.function.evaluate(persona))
    const next_calculation = this.calculations.find((c: Calculation) =>
      current_decisions.map((d: Decision) => d.target_id).includes(c.id)
    )
    if (!next_calculation) return audit
    // Continue traversal
    return this.traverse(next_calculation, persona, audit)
  }
}
export default Engine
