import { UserIcon, KeyIcon, ArrowRightIcon } from '@heroicons/react/24/outline'
import { Form, useActionData, useLocation } from 'react-router-dom'
import { Card, Subtitle, TextInput, Title } from '@tremor/react'

import { Button } from './Button'

export default function LoginForm() {
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const from = params.get('from') || '/'

  const actionData = useActionData() as { error: string } | undefined

  return (
    <Form method="post" replace className="space-y-3">
      <input type="hidden" name="redirectTo" value={from} />
      <Card className="flex-1 px-6 pb-4 pt-8">
        <Title className="mb-3">Please log in to continue.</Title>
        <div className="w-full">
          <div className="mt-4">
            <label className="mb-3 mt-5 block" htmlFor="username">
              <Subtitle>Username</Subtitle>
            </label>
            <TextInput
              className="py-[9px] space-x-4 pr-4"
              id="username"
              type="text"
              name="username"
              placeholder="Enter your username"
              required
              icon={UserIcon}
            />
          </div>
          <div className="mt-4">
            <label className="mb-3 mt-5 block" htmlFor="password">
              <Subtitle>Password</Subtitle>
            </label>
            <TextInput
              className="py-[9px] space-x-4 pr-4"
              id="password"
              type="password"
              name="password"
              placeholder="Enter password"
              required
              minLength={6}
              icon={KeyIcon}
            />
          </div>
        </div>
        <LoginButton />
        <div className="flex h-8 items-end space-x-1">
          {/* Add form errors here */}
          {actionData && actionData.error ? (
            <p style={{ color: 'red' }}>{actionData.error}</p>
          ) : null}
        </div>
      </Card>
    </Form>
  )
}

function LoginButton() {
  return (
    <Button className="mt-8 w-full">
      Log in <ArrowRightIcon className="ml-auto h-5 w-5 text-gray-50" />
    </Button>
  )
}
