import { memo, useEffect, useState } from 'react'
import { Button, Select, SelectItem, TextInput } from '@tremor/react'
import { BoltIcon } from '@heroicons/react/24/outline'
import { v4 as uuid } from 'uuid'
import { useStateStore } from '../stores/StateStore'
import { useVariableStore } from '../stores/VariableStore'
const icon = BoltIcon
const type = 'Variable'

export { icon, type }

const Variable = memo(function Variable({ parentId, id }: any) {
  const { variables, replaceVariables } = useVariableStore()
  const { state, setState } = useStateStore()
  const [addItem, setAddItem] = useState(false)
  const [addItemValue, setAddItemValue] = useState('')
  const [namespacedState, setNamespacedState] = useState('3')

  const setNamespacedStateWrapper = (data: any) => {
    if (data === 'create') {
      setAddItem(true)
    } else {
      const obj = { [id]: data }
      const updated = { ...state[parentId], ...obj }
      const updatedStateObject = { [parentId]: updated }
      setState(updatedStateObject)
      setNamespacedState(data)
    }
  }

  useEffect(() => {
    console.log(
      `inside the variable with our parent id ${parentId} and our id ${id} ${JSON.stringify(
        state[parentId]
      )}`
    )

    // TODO
    // find variable if it already exists here!
    const parent = state[parentId]
    let value = ''
    try {
      value = (parent as any)[id]
    } catch (e) {
      console.log(`error e ${e}`)
    }
    setNamespacedState(value)
  }, [])

  const addItemValueChanged = (e: string) => {
    setAddItemValue(e)
  }

  function toKebabCase(input: string): string {
    return input
      .toLowerCase()
      .replace(/\s+/g, '-') // Replace spaces with hyphens
      .replace(/[^a-z0-9-]/g, '') // Remove non-alphanumeric characters (except hyphens)
  }

  const saveNewVariable = () => {
    const key = toKebabCase(addItemValue)
    const newVariable = {
      key: key,
      name: addItemValue,
      value: key,
      id: uuid()
    }
    replaceVariables([newVariable])
    setAddItemValue('')
    setAddItem(false)
  }

  useEffect(() => {
    console.log(
      `inside the variable with our parent id ${parentId} and our id ${id} ${JSON.stringify(
        state[parentId]
      )}`
    )

    // TODO
    // find variable if it already exists here!
    const parent = state[parentId]
    const value = (parent as any)[id]
    setNamespacedState(value)
  }, [])
  return (
    <>
      {!addItem && (
        <Select
          value={namespacedState}
          onValueChange={setNamespacedStateWrapper}
        >
          {variables.map((v: any, i: any) => (
            <SelectItem key={i} value={v.key}>
              {v.name || v.key}
            </SelectItem>
          ))}
        </Select>
      )}
      {addItem && (
        <>
          <TextInput
            value={addItemValue}
            onChange={e => addItemValueChanged(e.target.value)}
            placeholder="Search..."
          />
          <Button onClick={() => saveNewVariable()}>save</Button>
        </>
      )}
    </>
  )
})

export default Variable
