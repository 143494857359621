import { authProvider } from 'features/auth'

type Mapping = {
  hostname: string
  api: string
}
type ApiMapping = Record<string, Mapping>
const apiMapping: ApiMapping = {
  test: {
    hostname: 'workbench.test.paysauce.com',
    api: 'api.test.paysauce.app/v0.1'
  },
  prod: {
    hostname: 'workbench.prod.paysauce.com',
    api: 'api.prod.paysauce.app/v0.1'
  },
  dev: {
    hostname: 'localhost',
    api: 'localhost:8080/v0.1'
  }
}

function getApiUrl<T extends ApiMapping>(mapping: T) {
  try {
    const hostname = window.location.hostname
    const protocol = window.location.protocol

    let env
    for (const key in mapping) {
      if (mapping[key].hostname === hostname) {
        env = key
        break
      }
    }

    return `${protocol}//${mapping[env as keyof ApiMapping].api}`
  } catch (error) {
    console.error(error)
    throw new Error('Failed to get API url')
  }
}

const API_URL = getApiUrl(apiMapping)

const query = async (url: string, options: RequestInit = {}) => {
  const adminUiLogin = localStorage.getItem('ADMIN-UI-LOGIN')

  const response = await fetch(`${API_URL}${url}`, {
    ...options,
    headers: {
      Authorization: `Bearer ${
        adminUiLogin ? JSON.parse(adminUiLogin).access_token : ''
      }`,
      ...options.headers
    }
  })

  // Handle errors
  if (!response.ok) {
    // Auth failed
    if (response.status === 403) {
      authProvider.signout()

      // This will redirect the user to the login page if the access token is expired.
      // The url of the redirected login page keeps the location where it comes.
      // So when the user logs in again, it will go back to the original location.
      const { origin, pathname, search } = window.location
      const params = new URLSearchParams()
      params.set('from', `${pathname}${search}`)
      window.location.replace(`${origin}/login?${params.toString()}`)

      throw new Error('Forbidden')
    }

    throw new Error(response.statusText)
  }

  return response
}

export { query }
