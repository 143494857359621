import { memo, useEffect } from 'react'
import { Card, Text, Flex, Badge } from '@tremor/react'
import { Handle, Position } from 'reactflow'

import { useNodeDroppableSlots } from '../nodes/utils'
import usePersonaStore from '../stores/PersonaStore'
import Variable from './Variable'

const Start = memo(function Start({
  id,
  data: { node, onDrop: dropFunction }
}: any) {
  const { slots } = useNodeDroppableSlots({ count: node.params })
  const { contexts } = usePersonaStore()

  return (
    <Card className="space-y-4" onDrop={event => dropFunction(event, node, id)}>
      <Handle type="target" position={Position.Top} />
      <Text>{node.name}</Text>

      <Flex>
        {contexts.map(context => (
          <Badge key={context.id} color={context.colour}>
            Result: {context?.results[0]?.toString() || 'N/A'}
          </Badge>
        ))}
      </Flex>

      <Flex className="space-x-5" flexDirection="row">
        {slots.map(
          (_: any, i: any) =>
            slots[i] || <Variable parentId={id} id={i} key={i}></Variable>
        )}
      </Flex>
      <Handle type="source" position={Position.Bottom} />
    </Card>
  )
})
export default Start
