import {
  Card,
  Icon,
  Flex,
  Subtitle,
  Text,
  Select,
  SelectItem,
  IconProps
} from '@tremor/react'
import {
  DocumentTextIcon,
  CodeBracketIcon,
  ViewColumnsIcon,
  BanknotesIcon,
  BoltIcon,
  PowerIcon
} from '@heroicons/react/24/outline'
import { useNavigate } from 'react-router-dom'
import { authProvider, getLocalStorageLoginItem } from 'features/auth'

type SidebarItemProps = {
  title: string
  path: string
  icon: IconProps['icon']
}

const SidebarItem = ({ title, path, icon }: SidebarItemProps) => {
  const navigate = useNavigate()

  return (
    <Flex
      className="cursor-pointer"
      justifyContent="start"
      onClick={() => navigate(path)}
    >
      <Icon
        icon={icon}
        variant={window.location.pathname == path ? 'light' : 'simple'}
        size="md"
        tooltip={title}
      />
      <Text className="px-5">{title}</Text>
    </Flex>
  )
}

const Sidebar = () => {
  const username = getLocalStorageLoginItem()?.username

  return (
    <Card className="px-10 py-5" style={{ gridArea: 'nav', borderRadius: 0 }}>
      <Flex className="h-full" flexDirection="col">
        <Flex flexDirection="col" alignItems="start">
          <Flex flexDirection="col" alignItems="center">
            <img className="w-32" src="/paysauce.svg" alt="Paysauce" />
          </Flex>

          <Select className="my-5" value="new zealand">
            <SelectItem value="new zealand">New Zealand</SelectItem>
          </Select>

          {username !== undefined && (
            <>
              <Subtitle className="my-5">Data</Subtitle>
              <SidebarItem
                path="/"
                title="Employment"
                icon={DocumentTextIcon}
              />
              <SidebarItem
                path="/calculate"
                title="Calculate"
                icon={BanknotesIcon}
              />

              <Subtitle className="my-5">Rules</Subtitle>
              <SidebarItem
                path="/Orchestration"
                title="Orchestration"
                icon={ViewColumnsIcon}
              />
              <SidebarItem
                path="/rules"
                title="Rules"
                icon={DocumentTextIcon}
              />
              <SidebarItem
                path="/function"
                title="Functions"
                icon={CodeBracketIcon}
              />
            </>
          )}
        </Flex>

        {username !== undefined && (
          <Flex justifyContent="start" className="flex-col space-y-6">
            <div className="flex space-x-6">
              <Icon
                icon={BoltIcon}
                variant="solid"
                tooltip="Paysauce Admin"
                size="md"
              />
              <div>
                <Text>Paysauce Admin</Text>
                {username && <Subtitle>{username}</Subtitle>}
              </div>
            </div>

            <form className="w-full">
              <button
                className="flex h-[48px] w-full grow items-center justify-center gap-2 rounded-md p-3 text-sm font-medium hover:bg-sky-100 hover:text-blue-600 md:flex-none md:justify-start md:p-2 md:px-3"
                onClick={() => {
                  authProvider.signout()
                }}
              >
                <PowerIcon className="w-6" />
                <div className="hidden md:block">Sign Out</div>
              </button>
            </form>
          </Flex>
        )}
      </Flex>
    </Card>
  )
}
export default Sidebar
