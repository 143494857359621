import { createContext, useState } from 'react'

import { functionsList } from './NodeContext'

export const RuleContext = createContext({
  bind: (...args: any): any => {
    console.log({ args })
  },
  functions: []
})

export const RuleProvider = ({ children }: any) => {
  const bind = (name: string, body: string, args: any[]) => {
    setFunctions(functionsList)
  }

  const [functions, setFunctions]: any = useState(functionsList)

  return (
    <RuleContext.Provider value={{ bind, functions }}>
      {children}
    </RuleContext.Provider>
  )
}
