import Editor, { DiffEditor, useMonaco, loader } from '@monaco-editor/react'

import Sidebar from '../components/Sidebar'
import { useContext, useEffect, useRef, useState } from 'react'
import { Button, Flex, Subtitle, TextInput } from '@tremor/react'
import { RuleContext } from '../nodes/RuleContext'

export default function FunctionEditor() {
  const editorRef = useRef(null)

  const [name, setName] = useState('exampleFunction')

  const mount = (e: any) => (editorRef.current = e)

  const { bind, functions } = useContext(RuleContext)

  const example = `const fn = (a, b, c) => {
  return { a, b, c }
}`

  const save = () => {
    const args: any = example.match(/\((.*?)\)/)?.pop()
    const body: any = example.match(/\{(\n*.*?\n*)\}/)?.pop()
    if (!args) return
    const fn = new Function(...args.split(','), `${body}}`)
    const res = bind(name, example, ['a', 'b', 'c'])
  }

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: 'max-content 1fr',
        gridTemplateRows: 'min-content 1fr',
        gridTemplateAreas: `
          'nav header'
          'nav content'
        `,
        height: '100vh',
        overflow: 'hidden'
      }}
    >
      <Sidebar />

      <div
        ref={editorRef}
        className="h-full px-1"
        style={{ gridArea: 'content' }}
      >
        <Editor
          height="100%"
          defaultLanguage="javascript"
          defaultValue={example}
          onMount={mount}
        />
      </div>
    </div>
  )
}
