import ReactFlow, {
  Background,
  BackgroundVariant,
  Controls,
  Panel,
  ReactFlowProvider,
  Node,
  Edge
} from 'reactflow'
import { useEffect, useMemo, MouseEvent, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Button,
  Dialog,
  DialogPanel,
  TextInput,
  Title,
  Text
} from '@tremor/react'

import { query } from '../../lib/query'
import Context from '../../components/Personas'
import DecisionNode from './DecisionNode'
import PersonaEdge from './PersonaEdge'
import { colours } from '../../features/contexts/data'
import { getDiagram } from './dagre'
import useFunctionStore from '../../stores/FunctionStore'
import Sidebar from '../../components/Sidebar'
import usePersonaStore from '../../stores/PersonaStore'
import Engine, { Calculation, Decision } from './engine'
import { useMathStore } from '../../stores/MathStore'
import { ContextType, FlatResult, Result } from 'features/contexts'
import { createToggleHoverStyle } from 'features/contexts/utils'
import { exportToJson } from 'features/file'
import { useSelectedEdge } from './useSelectedEdge'
import { OrchestrationJson } from 'schemas'
import ImportDialog from './ImportDialog'

// Retrieve data form API
const fetchCalculations = async () => {
  const request = await query(`/calculation?page_size=99999`)
  return await request.json()
}
const fetchDecisions = async () => {
  const request = await query(`/decision?page_size=99999`)
  return await request.json()
}

const postDecisions = async (decisions: Decision[]) => {
  await query(`/decision/bulk`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(decisions)
  })
}

// Translate API data structures
const translateCalculation = (calculation: Calculation): Node => ({
  id: `${calculation.id}`,
  type: 'DecisionNode',
  data: {
    calculation,
    results: []
  },
  position: { x: 100, y: 150 }
})

const translateDecision = (decision: Decision): Edge => ({
  id: `e${decision.id}`,
  source: `${decision.source_id}`,
  target: `${decision.target_id}`,
  label: decision.expression,
  data: { decision }
})

// Translate digram structures
const translateNode = (node: Node): Calculation => ({
  id: node.data.calculation.id,
  name: node.data.calculation.name,
  description: node.data.calculation.description,
  expression: node.data.calculation.expression,
  function: null,
  active_from: node.data.calculation.active_from,
  created_at: node.data.calculation.created_at,
  updated_at: node.data.calculation.updated_at
})

const translateEdge = (edge: Edge): Decision => ({
  id: edge.data.decision.id || null,
  description: '',
  expression: edge.data.decision.expression,
  function: null,
  source_id: parseInt(edge.source),
  target_id: parseInt(edge.target),
  active_from: new Date(),
  created_at: new Date(),
  updated_at: new Date()
})

const nodeTypes = {
  DecisionNode,
  Context
}

const edgeTypes = {
  PersonaEdge
}

const fetchDiagram = async () => {
  const [calculations, decisions] = await Promise.all([
    fetchCalculations(),
    fetchDecisions()
  ])

  const { nodes, edges } = getDiagram(
    calculations.results.map(translateCalculation),
    decisions.results.map(translateDecision)
  )

  return { nodes, edges }
}

type EvaluatePathsProps = {
  contexts: ContextType[]
  engine: Engine | null
  nodes: Node[]
  edges: Edge[]
}
const evaluatePaths = ({
  contexts,
  engine,
  nodes,
  edges
}: EvaluatePathsProps) => {
  try {
    // Calculate results
    const calculated = contexts.map(c => {
      const persona = Object.fromEntries(
        c.properties.map(p => [p.name, p.value])
      )
      const results = engine?.evaluate(persona) as Result[]
      return { id: c.id, colour: c.colour, results }
    })

    // Flat array of all calculated results
    const results = calculated
      .map(c =>
        c.results.map(r => ({
          id: c.id,
          context_id: r.id,
          colour: c.colour,
          value: r.result
        }))
      )
      .flat() as unknown as FlatResult[]

    // Assign results to node data fields
    const nodeResults = nodes.map(node => ({
      ...node,
      data: {
        ...node.data,
        results: results.filter(f => f.context_id == node.id)
      }
    }))

    // Create edges
    const edgeResults = [...edges]
    for (const calculation of calculated) {
      const paths = calculation.results.map(r => r.id)
      const contextEdges = paths
        .map((_, i) =>
          i + 1 != paths.length ? [paths[i], paths[i + 1]] : null
        )
        .filter(f => f !== null)
        .map(f => {
          const [source, target] = f as number[]
          return {
            id: `e-${calculation.id}-${source}-${target}`,
            source: `${source}`,
            target: `${target}`,
            type: 'PersonaEdge',
            style: {
              stroke: colours[calculation.colour][5]
            },
            data: {
              persona: calculation.id || ''
            }
          }
        })
      edgeResults.push(...contextEdges)
    }

    return { nodeResults, edgeResults }
  } catch (e) {
    console.error(e)
    return { nodeResults: nodes, edgeResults: edges }
  }
}

export default function DashboardExample() {
  const hoveredEdgeIdRef = useRef<string | null>(null)
  const selectedEdgeRef = useRef<Edge | null>(null)

  const { mathStore } = useMathStore()
  const navigate = useNavigate()
  const {
    nodes,
    edges,
    setNodes,
    setEdges,
    onNodesChange,
    onEdgesChange,
    onConnect
  } = useFunctionStore()
  const { contexts } = usePersonaStore()
  const latestSelectedEdge = useSelectedEdge({
    edges,
    setEdges,
    selectedEdgeRef
  })

  const [dialog, setDialog] = useState<{
    isOpen: boolean
    hasError: boolean
  }>({ isOpen: false, hasError: false })
  const [isImportOpen, setIsImportOpen] = useState(false)

  const engine = useMemo(() => {
    try {
      const calculations = nodes
        .filter(n => n.type != 'Context')
        .map(translateNode)
      const decisions = edges
        .filter((edge: Edge) => edge.type != 'PersonaEdge')
        .map(translateEdge)
      return new Engine(calculations, decisions)
    } catch (error) {
      console.error(error)
      return null
    }
  }, [edges, nodes])

  const { nodeResults, edgeResults } = useMemo(
    () =>
      evaluatePaths({
        contexts,
        engine,
        nodes,
        edges
      }),
    [contexts, edges, engine, nodes]
  )

  const nodeClicked = (event: React.MouseEvent, node: Node) =>
    navigate(`/rules/${node.id}`)

  const handleEdgeClick = (e: MouseEvent, edge: Edge) => {
    // Skip selected effects for context edges
    if (edge.type === 'PersonaEdge') return

    setEdges(cur =>
      cur.map(c => {
        if (c.id === edge.id) {
          const edge = { ...c }
          edge.animated = true
          return edge
        }
        return c
      })
    )
  }

  const updateExpression = (expression: string) => {
    const selectedEdge = JSON.parse(
      JSON.stringify(selectedEdgeRef.current)
    ) as Edge // Deep copy
    selectedEdge.label = expression
    selectedEdge.data.decision.expression = expression

    try {
      selectedEdge.data.decision.function = mathStore.parse(expression)
    } catch (e) {
      console.error(e)
    } finally {
      setEdges(cur =>
        cur.map(c => (c.id === selectedEdge.id ? selectedEdge : c))
      )
    }
  }

  // Bundle the diagram and send to server
  const save = () => {
    const decisions = edges
      .filter((edge: Edge) => edge.type != 'PersonaEdge')
      .map(translateEdge)
    postDecisions(decisions)
  }

  const createToggleMouseHover =
    (type: 'on' | 'off') => (e: MouseEvent, edge: Edge) => {
      const lastHoveredEdgeId = hoveredEdgeIdRef.current
      type === 'on'
        ? (hoveredEdgeIdRef.current = edge.id)
        : (hoveredEdgeIdRef.current = null)

      // Skip hovering effects for context edges
      if (edge.type === 'PersonaEdge') return

      setEdges(cur =>
        cur.map(c => {
          if (
            (c.id === edge.id ||
              (type === 'on' && c.id === lastHoveredEdgeId)) &&
            !edge.selected // Not remove the hover styles of the selected edge
          ) {
            const edge = { ...c }
            const toggleHoverStyle =
              c.id === edge.id
                ? createToggleHoverStyle(type)
                : createToggleHoverStyle('off')
            toggleHoverStyle(edge)

            return edge
          }
          return c
        })
      )
    }

  const handleExport = async () => {
    try {
      const [calculations, decisions] = await Promise.all([
        fetchCalculations(),
        fetchDecisions()
      ])

      const jsonData: OrchestrationJson = {
        meta: {
          date_generated: new Date().toISOString()
        },
        data: {
          rules: calculations.results,
          decisions: decisions.results
        }
      }
      exportToJson(jsonData, 'orchestration')

      // Refreshing the page
      const { nodes, edges } = await fetchDiagram()
      setNodes(nodes)
      setEdges(edges)
      setDialog({ hasError: false, isOpen: false })
    } catch (error) {
      console.error(error)
      setDialog(cur => ({ ...cur, hasError: true }))
    }
  }

  // Fetch initial diagram from server
  useEffect(() => {
    fetchDiagram().then(diagram => {
      setEdges(diagram.edges)
      setNodes(
        [
          diagram.nodes,
          {
            id: 'context',
            type: 'Context',
            position: {
              x: -600,
              y: 0
            },
            data: {}
          }
        ].flat()
      )
    })
  }, [setEdges, setNodes])

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: 'max-content 1fr',
        gridTemplateRows: 'min-content 1fr',
        gridTemplateAreas: `
          'nav header'
          'nav content'
        `,
        height: '100vh',
        overflow: 'hidden'
      }}
    >
      <Sidebar />
      <ImportDialog
        isOpen={isImportOpen}
        onClose={() => setIsImportOpen(false)}
      ></ImportDialog>

      <main className="overflow-auto space-y-5" style={{ gridArea: 'content' }}>
        <ReactFlowProvider>
          <ReactFlow
            nodes={nodeResults}
            edges={edgeResults}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            nodeTypes={nodeTypes}
            edgeTypes={edgeTypes}
            onNodeDoubleClick={nodeClicked}
            onEdgeClick={handleEdgeClick}
            fitView={true}
            proOptions={{ hideAttribution: true }}
            onEdgeMouseEnter={createToggleMouseHover('on')}
            onEdgeMouseLeave={createToggleMouseHover('off')}
          >
            {latestSelectedEdge &&
              latestSelectedEdge.type !== 'PersonaEdge' && (
                <Panel className="w-96" position="top-left">
                  <TextInput
                    value={latestSelectedEdge.data?.decision?.expression}
                    onValueChange={updateExpression}
                  />
                </Panel>
              )}
            <Panel position="top-right">
              <span className="mr-8">
                <Button className="mr-1" onClick={() => setIsImportOpen(true)}>
                  Import
                </Button>
                <Button
                  onClick={() => {
                    setDialog(cur => ({ ...cur, isOpen: true }))
                  }}
                >
                  Export
                </Button>
              </span>
              <Button onClick={save}>Save</Button>
            </Panel>
            <Controls position="bottom-right" />
            <Background variant={BackgroundVariant.Dots} gap={20} size={1.5} />
          </ReactFlow>
        </ReactFlowProvider>

        <Dialog
          open={dialog.isOpen}
          onClose={val => setDialog({ isOpen: val, hasError: false })}
          static={true}
        >
          <DialogPanel>
            <Title className="mb-3">Warning</Title>
            <div className="space-y-3">
              <Text>
                The export action will create a file by using rules and
                decisions extracted from the server and overwrite all the
                current rules and decisions unsaved on the page after the action
                completes. Are you sure you want to proceed?
              </Text>
              {dialog.hasError && (
                <Text color="red">
                  Something wrong happened. Please try to export again.
                </Text>
              )}
            </div>
            <div className="mt-6 flex space-x-6">
              <Button
                onClick={() => setDialog({ isOpen: false, hasError: false })}
              >
                No
              </Button>
              <Button variant="light" onClick={handleExport}>
                Yes
              </Button>
            </div>
          </DialogPanel>
        </Dialog>
      </main>
    </div>
  )
}
