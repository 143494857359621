import React, { useCallback } from 'react'
import { useReactFlow } from 'reactflow'

export default function NodeContextMenu({
  id,
  top,
  left,
  right,
  bottom,
  ...props
}: any) {
  const { getNode, setNodes, addNodes, setEdges } = useReactFlow()
  const duplicateNode = useCallback(() => {
    const node: any = getNode(id)
    const position = {
      x: node.position.x + 50,
      y: node.position.y + 50
    }

    addNodes({ ...node, id: `${node.id}-copy`, position })
  }, [id, getNode, addNodes])

  const deleteNode = useCallback(() => {
    setNodes(nodes => nodes.filter(node => node.id !== id))
    setEdges(edges => edges.filter(edge => edge.source !== id))
  }, [id, setNodes, setEdges])

  return (
    <div
      style={{
        top,
        left,
        right,
        bottom,
        borderRadius: '10%',
        background: 'white',
        color: 'black',
        position: 'absolute',
        zIndex: 10,
        borderStyle: 'solid',
        boxShadow: '10px 19px 20px rgba(0, 0, 0, 10%)'
      }}
      {...props}
    >
      <button
        style={{
          border: 'none',
          display: 'block',
          padding: '0.5em',
          textAlign: 'left',
          width: '100%'
        }}
        onClick={duplicateNode}
      >
        duplicate
      </button>
      <button
        style={{
          border: 'none',
          display: 'block',
          padding: '0.5em',
          textAlign: 'left',
          width: '100%'
        }}
        onClick={deleteNode}
      >
        delete
      </button>
    </div>
  )
}
