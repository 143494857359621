import { Card, Title, List, ListItem, Flex, Text, Divider } from '@tremor/react'

import WaterfallGraph from './WaterfallGraph'

const CalculationCard = (props: any) => {
  // Retain order
  const itemOrder = ['GROSS', 'NET', 'PAYE', 'KIWI_DED', 'KIWI_CONTRIB']
  const orderedItems = props.items.sort((a: any, b: any) => {
    const aIndex = itemOrder.findIndex(e => a.name == e)
    const bIndex = itemOrder.findIndex(e => b.name == e)
    return aIndex > bIndex ? 1 : -1
  })

  return (
    <Card className="space-y-4">
      <Title>Calculations</Title>
      <Flex flexDirection="col" className="space-y-2">
        {orderedItems
          .filter((f: any) => f.name != 'BUFFER')
          .map((e: any) => (
            <Flex key={e.name}>
              <Text>{e.name}</Text>
              <Text>${e.value.toFixed(2)}</Text>
            </Flex>
          ))}
      </Flex>
      <WaterfallGraph items={orderedItems} />
    </Card>
  )
}

export default CalculationCard
