import { create } from 'zustand'
import zukeeper from 'zukeeper'
import { persist, createJSONStorage } from 'zustand/middleware'
import {
  Connection,
  Edge,
  EdgeChange,
  Node,
  NodeChange,
  addEdge,
  OnNodesChange,
  OnEdgesChange,
  OnConnect,
  applyNodeChanges,
  applyEdgeChanges
} from 'reactflow'

export type State = {
  nodes: Node[]
  edges: Edge[]
  setNodes: (nodes: Node[]) => void
  setEdges: (
    arg: Edge[] | ((edgeState: State['edges']) => State['edges'])
  ) => void
  updatePersonaEdges: (id: string, edges: Edge[]) => void
  onNodesChange: OnNodesChange
  onEdgesChange: OnEdgesChange
  onConnect: OnConnect
}

export type Calculation = {
  id: string
  name: string
  description: string
  expression: string
  ruleset_id: string
  active_from: string
  active_until: null
  created_at: string
  updated_at: string
}

const useFunctionStore = create<State>()(
  persist(
    zukeeper((set: any, get: any) => ({
      nodes: [],
      edges: [],
      setEdges: (
        arg: Edge[] | ((edgeState: State['edges']) => State['edges'])
      ) => {
        typeof arg === 'function'
          ? set((state: State) =>
              Object.assign({}, state, { edges: arg(get().edges) })
            )
          : set((state: State) => Object.assign({}, state, { edges: arg }))
      },
      updatePersonaEdges: (persona_id: string, edges: Edge[]) =>
        set((state: State) => {
          const existing = state.edges.filter(
            (e: Edge) => e.data.persona != persona_id
          )
          return Object.assign({}, state, { edges: [existing, edges].flat() })
        }),
      setNodes: (nodes: Node[]) =>
        set((state: State) => {
          return Object.assign({}, state, { nodes })
        }),
      onNodesChange: (changes: NodeChange[]) =>
        set((state: State) =>
          Object.assign({}, state, {
            nodes: applyNodeChanges(changes, state.nodes)
          })
        ),
      onEdgesChange: (changes: EdgeChange[]) => {
        set({
          edges: applyEdgeChanges(changes, get().edges)
        })
      },
      onConnect: (connection: Connection) => {
        set({
          edges: addEdge(
            {
              ...connection,
              data: { decision: { expression: '' } }
            },
            get().edges
          )
        })
      }
    })),
    {
      name: 'function-storage',
      storage: createJSONStorage(() => sessionStorage)
    }
  )
)

export default useFunctionStore
// @ts-ignore
window.store = useFunctionStore
