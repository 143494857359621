import Sidebar from '../components/Sidebar'
import { ReactNode } from 'react'

type PageProps = {
  children: ReactNode
}

export default function Page({ children }: PageProps) {
  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: 'max-content 1fr',
        gridTemplateRows: 'min-content 1fr',
        gridTemplateAreas: `
          'nav header'
          'nav content'
        `,
        height: '100vh',
        overflow: 'hidden'
      }}
    >
      <Sidebar />

      <div className="h-full" style={{ gridArea: 'content' }}>
        {children}
      </div>
    </div>
  )
}
