import { Card, Flex, Text, Icon } from '@tremor/react'
import { v4 as uuid } from 'uuid'
import { DraggableProps } from '.'

export const DraggableNode = (props: DraggableProps) => {
  const onDragStart =
    (node_type: string, node_name: string) => (event: any) => {
      event.dataTransfer.setData('application/reactflow', node_type)
      event.dataTransfer.setData('application/reactflowname', node_name)
      event.dataTransfer.setData('application/reactflowid', uuid())
      event.dataTransfer.effectAllowed = 'move'
    }

  return (
    <Card
      className="py-2 px-2"
      onDragStart={onDragStart(props.node_type, props.node_name)}
      draggable
    >
      <Flex>
        <Icon className="pe-3" icon={props.icon} />
        <Text className="pe-2">{props.node_name}</Text>
      </Flex>
    </Card>
  )
}
