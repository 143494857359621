import { useState, useEffect, memo } from 'react'
import {
  Card,
  Flex,
  Text,
  Icon,
  Color,
  TextInput,
  NumberInput,
  DatePicker,
  DateRangePicker,
  IconProps,
  DateRangePickerValue,
  DatePickerValue
} from '@tremor/react'
import { BoltIcon, PlusIcon } from '@heroicons/react/24/solid'
import {
  CalculatorIcon,
  CalendarDaysIcon,
  ChatBubbleLeftEllipsisIcon,
  ArrowsPointingInIcon,
  ArrowsPointingOutIcon,
  Bars3Icon,
  DocumentDuplicateIcon,
  XMarkIcon,
  Bars3BottomLeftIcon
} from '@heroicons/react/24/outline'

import ColourPicker from './ColourPicker'
import OptionPicker from './OptionPicker'
import usePersonaStore from '../stores/PersonaStore'
import { ContextType, Property } from 'features/contexts'
import Menu from './Menu'

const typeIcons: Record<Property['type'], IconProps['icon']> = {
  text: ChatBubbleLeftEllipsisIcon,
  number: CalculatorIcon,
  date: CalculatorIcon,
  range: CalendarDaysIcon,
  select: Bars3Icon
}

type DynamicFieldProps = (
  | {
      type: 'number'
      value?: string | number
      onChange: (value: number) => void
    }
  | {
      type: 'range'
      value?: DateRangePickerValue
      onChange: (value: DateRangePickerValue) => void
    }
  | {
      type: 'date'
      value?: DatePickerValue
      onChange: (value: DatePickerValue) => void
    }
  | {
      type: 'text' | 'select'
      value?: string
      onChange: (value: string) => void
    }
) & { id: string }
const DynamicField = ({ type, value, onChange }: DynamicFieldProps) => {
  switch (type) {
    case 'number':
      return (
        <NumberInput
          placeholder="value"
          value={value}
          onValueChange={onChange}
        />
      )
    case 'date':
      return (
        <DatePicker
          placeholder="value"
          value={value}
          onValueChange={onChange}
        />
      )
    case 'range':
      return (
        <DateRangePicker
          placeholder="value"
          value={value}
          onValueChange={onChange}
        />
      )
    default:
      return (
        <TextInput
          placeholder="value"
          value={value}
          onChange={e => onChange(e.target.value)}
        />
      )
  }
}

const Context = memo(() => {
  const {
    contexts,
    updateContext,
    updateProperty,
    addContext,
    addProperty,
    deleteContext,
    duplicateContext,
    deleteProperty
  } = usePersonaStore()

  const [minimize, setMinimize] = useState(false)
  const [contextIndex, setContextIndex] = useState<number>(0)

  const currentContext = contexts.length > 0 ? contexts[contextIndex] : null

  const handleAddContext = () => {
    addContext()
    setContextIndex(contexts.length)
  }

  useEffect(() => {
    contexts.length === 0 ? setMinimize(true) : setMinimize(false)
  }, [contexts.length])

  useEffect(() => {
    contexts.length > 0 &&
      contextIndex >= contexts.length &&
      setContextIndex(contexts.length - 1)
  }, [contexts.length, contextIndex])

  return (
    <Card className="w-max" id="ContextCard">
      <Flex className="space-x-5" alignItems="start">
        <Flex className="space-y-2" flexDirection="col">
          <Icon
            variant="shadow"
            icon={minimize ? ArrowsPointingOutIcon : ArrowsPointingInIcon}
            onClick={() => setMinimize(!minimize)}
          />

          {contexts.map((context: ContextType, i) => (
            <Icon
              key={context.name}
              color={context.colour}
              variant="shadow"
              icon={BoltIcon}
              onClick={() => setContextIndex(i)}
            />
          ))}
          <Icon variant="shadow" icon={PlusIcon} onClick={handleAddContext} />
        </Flex>
        {!minimize && currentContext && (
          <Flex
            alignItems="start"
            className="w-min space-y-2"
            flexDirection="col"
          >
            <Flex className="space-x-2 mb-5" flexDirection="row">
              <ColourPicker
                value={currentContext.colour}
                onValueChange={(colour: Color) =>
                  updateContext(currentContext, { colour })
                }
              />

              <TextInput
                value={currentContext.name}
                onChange={e =>
                  updateContext(currentContext, { name: e.target.value })
                }
              />
              <Menu
                icon={Bars3BottomLeftIcon}
                onValueChange={(type: any) => console.log(type)}
                options={Object.keys(typeIcons).map((k: string) => ({
                  type: k as Property['type']
                }))}
              >
                <Icon
                  variant="shadow"
                  tooltip="duplicate"
                  icon={DocumentDuplicateIcon}
                  onClick={() => duplicateContext(contextIndex)}
                />
                <Icon
                  variant="shadow"
                  icon={XMarkIcon}
                  tooltip="delete"
                  onClick={() => deleteContext(currentContext.id)}
                />
              </Menu>
            </Flex>

            {currentContext.properties.map((p: Property) => (
              <Flex key={p.id} className="space-x-2" flexDirection="row">
                <OptionPicker
                  icon={typeIcons[p.type]}
                  value={p.type}
                  onValueChange={type =>
                    updateProperty(currentContext.id, p.id, { type: type.type })
                  }
                  options={Object.keys(typeIcons).map((k: string) => ({
                    type: k as Property['type']
                  }))}
                  render={(option, select) => (
                    <Icon
                      key={option.type}
                      className="m-1"
                      id={option.type}
                      icon={typeIcons[option.type]}
                      tooltip={option.type}
                      variant="shadow"
                      onClick={() => select(option)}
                    />
                  )}
                />
                <TextInput
                  placeholder="name"
                  value={p.name}
                  onChange={e =>
                    updateProperty(currentContext.id, p.id, {
                      name: e.target.value
                    })
                  }
                />
                <Text>=</Text>
                <DynamicField
                  id={p.id}
                  type={p.type}
                  value={p.value}
                  onChange={(value: unknown) =>
                    updateProperty(currentContext.id, p.id, { value })
                  }
                />
                <Icon
                  variant="shadow"
                  icon={XMarkIcon}
                  tooltip="delete"
                  onClick={() => {
                    deleteProperty(currentContext.id, p.id)
                  }}
                />
              </Flex>
            ))}

            <Icon
              variant="shadow"
              icon={PlusIcon}
              tooltip="add property"
              onClick={() => addProperty(currentContext.id)}
            />
          </Flex>
        )}
      </Flex>
    </Card>
  )
})

export default Context
