import { BaseEdge, getStraightPath } from 'reactflow'

export default function PersonaEdge({
  sourceX,
  sourceY,
  targetX,
  targetY,
  ...props
}: any) {
  const [edgePath] = getStraightPath({
    sourceX,
    sourceY,
    targetX,
    targetY
  })

  return <BaseEdge path={edgePath} {...props} />
}
