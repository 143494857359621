import {
  Card,
  Title,
  Grid,
  Button,
  Col,
  Icon,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Text,
  NumberInput,
  Flex,
  Subtitle
} from '@tremor/react'
import { Outlet } from 'react-router-dom'
import React, { useState, useEffect } from 'react'
import {
  XMarkIcon,
  PlusIcon,
  UserIcon,
  ArrowRightIcon,
  ArrowLeftIcon,
  BoltIcon
} from '@heroicons/react/24/outline'
import { parseISO, format } from 'date-fns'

import { query } from '../../lib/query'

import Sidebar from '../../components/Sidebar'
import CalculationCard from '../../components/CalculationCard'

import { io } from 'socket.io-client'
const socket = io(`ws://localhost:8080`, {})

import { usePagination } from '../../hooks/usePagination'

export default function DashboardExample(props: any) {
  const { results, page, total, size, setPage, previous, next, pages, fetch } =
    usePagination('/employment')

  const [xpage, setXPage] = useState('list')
  const [employment, setEmployment] = useState<any>({})
  const [count, setCount] = useState('')
  const [polling, setPolling] = useState(null)

  const [calculating, setCalculating] = useState(false)

  const [account, setAccount] = useState<any>(null)
  const viewEmployment = async (account: any) => {
    setAccount(account)
    setXPage('employment')
  }

  const calculate = async () => {
    try {
      await query(`/simulation/run`, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json'
        }
      })
    } catch (e) {
      console.error(e)
    }
  }

  const seedEmployment = async () => socket.emit('seed', { count: count || 0 })
  socket.on('refresh', fetch)

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: 'max-content 1fr',
        gridTemplateRows: 'min-content 1fr',
        gridTemplateAreas: `
          'nav header'
          'nav content'
        `,
        height: '100vh',
        overflow: 'hidden'
      }}
    >
      <Sidebar />

      <main
        className="p-12 overflow-auto space-y-5"
        style={{ gridArea: 'content' }}
      >
        <Outlet />
      </main>
    </div>
  )
}
