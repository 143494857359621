import { useState } from 'react'
import { nodeDefinitons, nodeTypes } from './NodeContext'
import { v4 as uuid } from 'uuid'

// Render components in slots they are dropped in
const useDroppableSlots = ({ count = 0 }: any) => {
  const [slots, setSlots] = useState(Array(count).fill(''))

  const onDrop = (location: string, Node: any) => {
    setSlots((data: any) => {
      data[location] = <Node />
      return [...data]
    })
  }

  return { slots, onDrop }
}

// Render react flow components in slots
const useNodeDroppableSlots = ({ count = 0, onChange }: any) => {
  const { slots, onDrop: drop } = useDroppableSlots({ count })

  const onDrop = (i: any) => (event: any) => {
    event.stopPropagation()
    const type = event.dataTransfer.getData('application/reactflow')
    const data = {
      node: nodeDefinitons.find((f: any) => f.name == type),
      onChange: (e: any) => onChange(e, i)
    }
    const Node = nodeTypes[type]
    drop(i, () => <Node id={uuid()} data={data} />)
  }

  return { slots, onDrop }
}

export { useDroppableSlots, useNodeDroppableSlots }
