import {
  AssignmentNode,
  ConstantNode,
  MathNode,
  OperatorNode,
  SymbolNode
} from 'mathjs'
import { StackObject } from '.'
import { v4 as uuid } from 'uuid'
import { CustomNode, StateObject, useStateStore } from '../../stores/StateStore'
import { useVariableStore } from '../../stores/VariableStore'
import { nodeDefinitons } from '../../nodes/NodeContext'
import { Edge } from 'reactflow'
import toast from 'react-hot-toast'
import { query } from '../../lib/query'
import { useMathStore } from '../../stores/MathStore'
const mathStore = useMathStore.getState().mathStore
export const initialNodes: any = [
  {
    id: uuid(),
    type: 'Context',
    position: {
      x: 18,
      y: 180
    },
    data: {}
  }
]
export const transformNodestoExpression = (
  nodes: Node[] | any[],
  edges: any[],
  ruleName: string
) => {
  const nodesWithoutStart = nodes.filter(n => n.type.toLowerCase() !== 'start')
  const startNode = nodes.filter(n => n.type.toLowerCase() === 'start')
  const mappedNodes: CustomNode[] = nodesWithoutStart.map(node => {
    const customNode: CustomNode = {
      id: node.id,
      type: node.type,
      position: {
        x: node.position.x,
        y: node.position.y
      },
      width: node.width,
      height: node.height,
      state:
        node.type.toLowerCase() !== 'end' ? node.state : [node.name, 'prev'],
      operation: node.operator
    }
    return customNode
  })
  const calculatedString = bfs(mappedNodes, edges, '0')
  toast(`Here is the exported string! ${calculatedString}`)
}
export const updateRuleInDatabase = async (
  calculatedString: string,
  ruleName: string,
  ruleId: any
) => {
  const data = JSON.stringify({
    name: ruleName,
    description: ruleName,
    expression: calculatedString,
    ruleset_id: 1,
    active_from: new Date().toISOString()
  })
  // Response is NOT json currently
  return await query(`/calculation/${ruleId}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json'
    },
    body: data
  })
}

export const createRuleInDatabase = async (
  calculatedString: string,
  ruleName: string
) => {
  const data = JSON.stringify({
    name: ruleName,
    description: ruleName,
    expression: calculatedString,
    ruleset_id: 1,
    active_from: new Date().toISOString(),
    // Setting some values so the API doesn't complain
    active_until: new Date('2050/1/1').toISOString(),
    created_at: new Date().toISOString(),
    updated_at: new Date().toISOString()
  })
  const request = await query(`/calculation`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    body: data
  })
  return await request.json()
}

const setState = (obj: any) => {
  return useStateStore.getState().setState(obj)
}
const replaceVariables = (variables: any) => {
  return useVariableStore.getState().replaceVariables(variables)
}

function bfs(
  nodesArray: CustomNode[],
  edgesArray: Edge[],
  startNodeId: string
) {
  // Create a visited object to track the nodes that have been visited
  const visited: { [key: string]: boolean } = {}

  // Create a queue and add the start node to it
  const queue: string[] = [startNodeId]

  // Create an empty function string
  let calculatedString = ''

  // first iteration, string is empty, replace it with first calculation 3+3, surrouned by brackets
  // second and beyond, form calculation as (prev+4) and use replace prev with calculatedString
  const replacePrev = (fs: string, ss: string) => ss.replace('prev', fs) //SS contains prev

  // Mark the start node as visited
  visited[startNodeId] = true

  while (queue.length > 0) {
    // Remove the first node from the queue
    const nodeId = queue.shift() as string

    // Find all edges from this node
    const edgesFromNode = edgesArray.filter(edge => edge.source === nodeId)

    // Add all unvisited nodes connected by these edges to the queue
    for (const edge of edgesFromNode) {
      if (!visited[edge.target]) {
        queue.push(edge.target)
        const result = nodesArray
          .filter(node => node.id === edge.target)
          .shift()

        const resultString = `(${result!.state[0]}${result!.operation}${
          result!.state[1]
        })`
        calculatedString = calculatedString
          ? replacePrev(calculatedString, resultString)
          : resultString
        visited[edge.target] = true
      }
    }
  }
  return calculatedString
}

export function calculatePrefix(prefixArray: StackObject[]): any[] {
  const reversedArray: StackObject[] = prefixArray.reverse()
  const stack: (number | string)[] = []
  const objectStack: object[] = []
  const edgesArray: object[] = []
  let count = 1
  for (const element of reversedArray) {
    if (/[a-zA-Z0-9-_]+/.test(element.symbol.toString())) {
      stack.push(element.symbol)
    } else {
      const operand1 = stack.pop()
      const operand2 = stack.pop()
      if (['+', '-', '*', '/', '='].includes(element.symbol as string)) {
        const newObj = {
          id: (count++).toString(),
          type: element.symbol === '=' ? 'End' : 'Function',
          name: element.name,
          key: element.name,
          operator: element.symbol,
          state: [operand1, operand2],
          data: {
            node: nodeDefinitons.find((f: any) => f.name == element.name)
          }
        }
        const obj: StateObject = {}
        const replace: any = []
        newObj.state.forEach((variable: any) => {
          try {
            replace.push({
              key: variable.toString(),
              name: variable.toString(),
              value: variable.toString(),
              id: uuid()
            })
          } catch (e) {
            console.log(`eraor ${e}`)
          }
        })

        replaceVariables(replace)
        try {
          obj[newObj.id.toString()] = {
            '0': newObj?.state[0]?.toString(),
            '1': newObj?.state[1]?.toString()
          }
        } catch (e) {
          console.log(`ERROR ${e}`)
        }
        setState(obj)
        stack.push('prev')
        objectStack.push(newObj)
        const newEdge: Edge = {
          id: uuid(),
          source: (Number(newObj.id) - 1).toString(),
          target: newObj.id.toString()
        }
        edgesArray.push(newEdge)
      }
    }
  }
  return [objectStack, edgesArray]
}
export const transformRule = (rule: string): any[] => {
  if (!rule) return []

  const node: MathNode = mathStore.parse(rule)
  const stack: any = []

  node.traverse(function (node) {
    switch (node.type) {
      case 'OperatorNode':
        {
          const meta = (node as OperatorNode).op
          const newObj: StackObject = {
            symbol: meta,
            name: meta
          }
          stack.push(newObj)
        }
        break
      case 'ConstantNode':
        {
          const meta = (node as ConstantNode).value
          const newObj: StackObject = {
            symbol: meta,
            name: meta
          }
          stack.push(newObj)
        }
        break
      case 'SymbolNode':
        {
          const meta = (node as SymbolNode).name
          const newObj: StackObject = {
            symbol: meta,
            name: meta
          }
          stack.push(newObj)
        }
        break
      case 'AssignmentNode':
        {
          const meta = (node as AssignmentNode).object.name
          const newObj: StackObject = {
            symbol: '=',
            name: meta
          }
          stack.push(newObj)
        }
        break
    }
  })
  const [nodeArray, edgesArray] = calculatePrefix(stack)

  nodeArray.splice(0, 0, { id: '0', type: 'Start' })
  nodeArray.forEach((node: any, index: number) => {
    node['position'] = { x: 700, y: index === 0 ? -85 : index * 200 }
  })
  return [nodeArray, edgesArray]
}
