import { LoaderFunctionArgs, redirect } from 'react-router-dom'

import { query } from 'lib/query'
import { authProvider } from './authProvider'
import { validateData } from 'utils/functions'
import { LoginSchema } from 'schemas/auth.schema'

export async function loginAction({ request }: LoaderFunctionArgs) {
  const formData = await request.formData()
  const username = formData.get('username') as string | null
  const password = formData.get('password') as string | null

  // Validate our form inputs and return validation errors via useActionData()
  if (!username) {
    return {
      error: 'You must provide a username to log in'
    }
  }
  if (!password) {
    return {
      error: 'You must provide a password to log in'
    }
  }

  const callback = async () => {
    try {
      const validatedData = validateData(LoginSchema, {
        username,
        password
      })

      return await query('/login', {
        method: 'post',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(validatedData)
      })
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  // Sign in and redirect to the proper destination if successful.
  try {
    await authProvider.signin(callback, username)

    const redirectTo = formData.get('redirectTo') as string | null
    return redirect(redirectTo || '/')
  } catch (error) {
    // Unused as of now but this is how you would handle invalid
    // username/password combinations - just like validating the inputs
    // above
    return {
      error: 'Invalid login attempt'
    }
  }
}
