import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider
} from 'react-router-dom'
import { Toaster } from 'react-hot-toast'

import Home from './pages/home/Index'
import List from './pages/home/List'
import Employment from './pages/home/Employment'
import Rules from './pages/rules'
import Calculate from './pages/Calculate'
import Function from './pages/Function'
import Orchestration from './pages/orchestration/index'
import Login from 'pages/Login'

import { RuleProvider } from './nodes/RuleContext'
import { StateProvider } from './nodes/StateContext'
import { loginLoader, loginAction, protectedLoader } from 'features/auth'

export default function App() {
  const router = createBrowserRouter(
    createRoutesFromElements([
      <Route key="home" path="/" element={<Home />} loader={protectedLoader}>
        <Route key="list" path="" element={<List />} />,
        <Route key="list" path="/:id/employment" element={<Employment />} />
      </Route>,
      <Route
        key="rules"
        path="/rules"
        element={<Rules />}
        loader={protectedLoader}
      />,
      <Route
        key="rules"
        path="/rules/:id"
        element={<Rules />}
        loader={protectedLoader}
      />,
      <Route
        key="calculate"
        path="/calculate"
        element={<Calculate />}
        loader={protectedLoader}
      />,
      <Route
        key="function"
        path="/function"
        element={<Function />}
        loader={protectedLoader}
      />,
      <Route
        key="orchestration"
        path="/orchestration"
        element={<Orchestration />}
        loader={protectedLoader}
      />,
      <Route
        key="login"
        action={loginAction}
        loader={loginLoader}
        path="/login"
        element={<Login />}
      />
    ])
  )

  return (
    <RuleProvider>
      <StateProvider>
        <Toaster></Toaster>
        <RouterProvider router={router} />
      </StateProvider>
    </RuleProvider>
  )
}
