import { Button, TextInput, Text } from '@tremor/react'
import { useState } from 'react'

const Prefix = ({ prefix }: any) => {
  if (prefix) {
    return <Text className="mx-2 font-light">{prefix}</Text>
  } else {
    return null
  }
}

const TextLabel = ({ value, placeholder, onClick }: any) => {
  if (value) {
    return (
      <span className="mx-2 cursor-pointer" onClick={() => onClick()}>
        {value}
      </span>
    )
  }
  return (
    <span
      className="mx-2 font-lighter italic cursor-pointer"
      onClick={() => onClick()}
    >
      {placeholder}
    </span>
  )
}

interface InlineTextInputProps {
  value: string
  prefix?: string
  placeholder?: string
  onEditStart?: () => void
  onEditValidate?: (editValue: string) => boolean
  onEditClose?: (editValue: string) => void
}

const InlineTextInput = (props: InlineTextInputProps) => {
  const [editActive, setEditActive] = useState(false)
  const [editValue, setEditValue] = useState(props.value)

  function startEdit() {
    setEditValue(props.value) // pickup any model changes
    setEditActive(true)
    if (props.onEditStart) {
      props.onEditStart()
    }
  }

  function completeEdit() {
    if (props.onEditValidate) {
      if (!props.onEditValidate(editValue)) {
        return
      }
    }
    setEditActive(false)
    if (props.onEditClose) {
      props.onEditClose(editValue)
    }
  }

  if (editActive) {
    return (
      <div>
        <Prefix prefix={props.prefix}></Prefix>
        <TextInput
          placeholder={props.placeholder}
          type="text"
          value={editValue}
          onChange={e => setEditValue(e.currentTarget?.value)}
        ></TextInput>
        <span>
          <Button size="md" onClick={completeEdit}>
            Apply
          </Button>
        </span>
      </div>
    )
  }
  return (
    <div>
      <Prefix prefix={props.prefix}></Prefix>
      <TextLabel
        value={props.value}
        placeholder={props.placeholder || ''}
        onClick={startEdit}
      ></TextLabel>
      <span className="mx-2">
        <Button size="md" onClick={startEdit}>
          Edit
        </Button>
      </span>
    </div>
  )
}

export default InlineTextInput
