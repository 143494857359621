import { ReactFlowProvider } from 'reactflow'
import 'reactflow/dist/style.css'
import Page from '../Page'

import { ReactFlowDiagram } from './ReactFlowDiagram'
export const fitViewOptions = {
  padding: 1
}

export type DraggableProps = {
  node_type: string
  node_name: string
  icon: any
}
export type StackObject = {
  name: string | number
  symbol: string | number
}

export default function DashboardExample() {
  return (
    <Page>
      <ReactFlowProvider>
        <ReactFlowDiagram />
      </ReactFlowProvider>
    </Page>
  )
}
