import { useState, useRef } from 'react'
import { Card, Flex, Icon } from '@tremor/react'
import { EyeDropperIcon } from '@heroicons/react/24/solid'

function Menu({ icon = EyeDropperIcon, value, colour, children }: any) {
  const focus = useRef<HTMLDivElement>(null)
  const [showing, setShowing] = useState<boolean>(false)

  const show = () => {
    setShowing(true)
    focus.current?.focus()
  }
  const hide = () => setShowing(false)

  return (
    <Flex
      ref={focus}
      onBlur={hide}
      onMouseEnter={show}
      onMouseLeave={hide}
      tabIndex={1}
      className="relative w-max outline-none"
    >
      <Icon
        variant="shadow"
        color={colour}
        icon={icon}
        tooltip={value as string}
        onClick={show}
      />
      <div className={`absolute z-10 ${!showing && 'hidden'}`}>
        <Card
          className={`p-2 w-max space-x-2 translate-y-full -translate-x-1/3`}
        >
          {children}
        </Card>
      </div>
    </Flex>
  )
}
export default Menu
